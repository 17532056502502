import {Button, Col, Row, Table, DatePicker, Radio} from "antd";
import Icon from "@ant-design/icons";
import ModalHeader from "features/shared/ModalHeader";

export default function ConfirmModal({
                                         onClose,
                                         onConfirm,
                                         text,
                                     }: any) {

    return (
        <div className={"modal-wrapper"} style={{padding: "30px"}}>
            <ModalHeader
                title={`Вы уверены что хотите ${text}`}
                onClose={onClose}
            />

            <div className={"keyword-select-buttons-container"}>
                <Button
                    className={"button"}
                    style={{ fontSize: "12px", width: '50%' }}
                    type={"primary"}
                    onClick={async () =>{
                        await onConfirm()
                        await onClose()
                    }}
                >
                    Подтвердить
                </Button>
                <Button
                    className={"button"}
                    style={{ fontSize: "12px", width: '50%' }}
                    type={"primary"}
                    ghost
                    onClick={onClose}
                >
                    Отмена
                </Button>
            </div>
        </div>
    );
}
