import React, { useState } from 'react'
import { Col, message, Modal, Row, Table } from 'antd'
import Icon from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ReactComponent as editIcon } from 'assets/images/edit-icon.svg'
import { renderTableText } from '../helpers/renderTableText'

import StatisticsTableCol from './StatisticsTableCol'
import StatisticsTableNote from './StatisticsTableNote'
import DynamicTable from './DynamicTable'
import TableItems from './TableItems'
import TableOnCard from './TableOnCard'
import TitleWithTooltip from 'features/actions/components/TitleWithTooltip'
import NoImage from 'assets/icon/no-image'
import ChangeValue from './ChanageValue'
import MinMax from './MinMax'
import SelectValue from './SelectValue'
import { addDateComment } from 'api/advertisingMirror'

type AdvertisingStatisticsItemProps = {
	item: any
	addComment: (data: { product_id: string | number; comment: string }) => void
	addDateComment: (data: {
		product_id: string | number
		comment: string
		date?: string
		weekId?: number
		avgWeekId?: number
	}) => void
	planOrders: (data: {
		product_id: string | number
		min: number
		max: number
	}) => void
	addOrChangeProductStatus: (data: {
		product_id: string | number
		actionStatus: string
		productStatus: string
	}) => void
	adStatus: (data: {
		product_id: string | number
		adType: string
		adRunStatus: string
	}) => void
}

const statusConfig = {
	active: {
		color: '#4CAF50',
		emoji: '▶️',
	},
	disabled: {
		color: '#F44336',
		emoji: '❌',
	},
	paused: {
		color: '#FFC107',
		emoji: '⏸️',
	},
	unset: {
		color: '#0FC1F7',
		emoji: '🚫',
	},
}

const statusOptions = [
	{ value: 'active', shortTitle: '▶️', title: '▶️ Запустить' },
	{ value: 'disabled', shortTitle: '❌', title: '❌ Отключить' },
	{ value: 'paused', shortTitle: '⏸️', title: '⏸️ Приостановить' },
	{ value: 'unset', shortTitle: '🚫', title: '🚫 Не установлено' },
]
const productStatusOptions = [
	{ value: 'new', color: '#ffe5a0', title: 'Новинка' },
	{ value: 'focus', color: '#11734b', title: 'Фокус' },
	{ value: 'discharge', color: '#ffcfc9', title: 'Слить' },
	{ value: 'zero', color: '#753800', title: 'Остаток 0' },
	{ value: 'in_work', color: '#d4edbc', title: 'В работе' },
]
const actionStatusOptions = [
	{ value: 'true', color: '#e6cff2', title: 'В акции' },
	{ value: 'false', color: '#e6e6e6', title: 'Без акции' },
]

export const AdvertisingStatisticsItem = ({
	item,
	addComment,
	planOrders,
	addOrChangeProductStatus,
	adStatus,
	addDateComment,
}: AdvertisingStatisticsItemProps) => {
	const priceWithDiscountWb = (
		Number(item?.currentFullPrice) -
		(Number(item?.currentFullPrice) * Number(item?.discount)) / 100
	)?.toFixed(2)
	const priceWithDiscountSpp = (
		Number(priceWithDiscountWb) -
		(Number(priceWithDiscountWb) * item?.spp) / 100
	).toFixed(2)

	// const itemData = [
	// 	{
	// 		title: '',
	// 		text: item.product.status,
	// 		width: '45%',
	// 		backgroundColor: '#ffe5a0',
	// 	},
	// 	{
	// 		title: '',
	// 		text: item.product.comments,
	// 		width: '45%',
	// 		backgroundColor: '#fff',
	// 	},
	// 	{
	// 		title: '',
	// 		text: item.product.action,
	// 		width: '45%',
	// 		backgroundColor: '#e6e6e6',
	// 	},
	// 	{
	// 		title: `${item.product.minOrders}-${item.product.maxOrders} заказов`,
	// 		text: `x${item.product.boost}`,
	// 		width: '45%',
	// 		backgroundColor: '#E6E7E8',
	// 	},
	// 	{
	// 		title: 'Итого по складам',
	// 		text: `${item.product.totalStocks} шт`,
	// 		width: '45%',
	// 		backgroundColor: '#d5a6bd',
	// 	},
	// 	{
	// 		title: 'хватит на',
	// 		text: item.product.leftOn,
	// 		width: '45%',
	// 		backgroundColor: '#fff2cc',
	// 	},
	// ]

	const columnsData = [
		{
			key: 'ordersTotal',
			color: '#a4089f',
			textColor: '#fff',
			fontWeight: 600,
			avgFontWeight: 600,
			symbol: '',
			avgSymbol: '',
		},
		{
			key: 'incomeTotal',
			color: '#a4089f',
			textColor: '#fff',
			fontWeight: 700,
			fontSize: 16,
			avgFontWeight: 700,
			symbol: '',
			avgSymbol: '',
		},
		{
			key: 'storeMarginTotal',
			color: '#741b47',
			textColor: '#fff',
			fontWeight: 700,
			fontSize: 12,
			avgFontWeight: 700,
			symbol: '%',
		},
		{
			key: 'drrStoreTotal',
			color: '#4c1130',
			textColor: '#fff',
			fontWeight: 700,
			avgFontWeight: 700,
			symbol: '',
			avgSymbol: '',
		},
		{
			key: 'rubX',
			color: '#434343',
			textColor: '#fff',
			fontWeight: 700,
			symbol: ' ₽',
		},
		{
			key: 'comment',
			color: '#93c47d',
			textColor: '#000',
			fontWeight: 600,
			avgFontWeight: 700,
			symbol: '',
		},
		{
			key: 'articleMargin',
			color: '#b6d7a8',
			textColor: '#000',
			fontWeight: 600,
			avgFontWeight: 700,
			avgFontSize: 14,
			fontSize: 12,
			symbol: '%',
			digitsAfterComa: 2,
		},
		{
			key: 'priceWithoutSpp',
			color: '#efefef',
			textColor: '#000',
			fontWeight: 300,
			symbol: '',
			avgSymbol: '',
		},
		{
			key: 'orders',
			color: '#efefef',
			textColor: '#000',
			fontWeight: 700,
			avgSymbol: ' шт.',
			symbol: '',
		},
		{
			key: 'carts',
			color: '#efefef',
			textColor: '#000',
			avgSymbol: ' шт.',
			symbol: '',
		},
		{
			key: 'shows',
			color: '#efefef',
			textColor: '#000',
			symbol: '',
			avgSymbol: '',
		},
		{
			key: 'spend',
			color: '#efefef',
			textColor: '#000',
			symbol: ' ₽',
		},
		{
			key: 'clicks',
			color: '#efefef',
			textColor: '#000',
			symbol: '',
			avgSymbol: '',
		},
		{
			key: 'transitions',
			color: '#efefef',
			textColor: '#000',
			symbol: '',
			avgSymbol: '',
		},
		{
			key: 'ctr',
			color: '#efefef',
			textColor: '#000',
			fontWeight: 700,
			symbol: '%',
			digitsAfterComa: 2,
		},
		{
			key: 'cr1',
			color: '#efefef',
			textColor: '#000',
			fontWeight: 700,
			symbol: '%',
			digitsAfterComa: 2,
		},
		{
			key: 'cr2',
			color: '#efefef',
			textColor: '#000',
			fontWeight: 700,
			symbol: '%',
			digitsAfterComa: 2,
		},
		{
			key: 'cpc',
			color: '#efefef',
			textColor: '#000',
			fontWeight: 700,
			symbol: ' ₽',
			digitsAfterComa: 2,
		},
		{
			key: 'cpo',
			color: '#efefef',
			textColor: '#000',
			fontWeight: 700,
			symbol: ' ₽',
			digitsAfterComa: 2,
		},
		{
			key: 'drrOrders',
			color: '#d9d9d9',
			textColor: '#000',
			fontWeight: 600,
			avgFontWeight: 700,
			symbol: '%',
			digitsAfterComa: 2,
		},
		{
			key: 'drrBuyout',
			color: '#b7b7b7',
			textColor: '#000',
			fontWeight: 600,
			avgFontWeight: 700,
			symbol: '%',
			digitsAfterComa: 2,
		},
	]

	const tableVerticalHeaders = [
		{
			key: '1',
			name: <b>Заказы</b>,
			color: '#a4089f',
			textColor: '#fff',
		},
		{
			key: '2',
			name: <b>Прибыль</b>,
			color: '#a4089f',
			textColor: '#fff',
		},
		{
			key: '3',
			name: (
				<b
					style={{
						display: '-webkit-box',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 1,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'normal',
					}}
				>
					Маржинальность магазина
				</b>
			),
			color: '#741b47',
			textColor: '#fff',
		},
		{
			key: '4',
			name: (
				<b
					style={{
						display: '-webkit-box',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 1,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'normal',
					}}
				>
					ДРР магазина (заказы)
				</b>
			),
			color: '#4c1130',
			textColor: '#fff',
		},
		{
			key: '4',
			name: (
				<TitleWithTooltip
					title={item.name}
					rows={1}
					color='#fff'
					fontWeight={700}
				/>
			),
			color: '#434343',
			textColor: '#fff',
		},
		{
			key: '5',
			name: (
				<a
					href={item?.linkOnSite}
					style={{
						textDecoration: 'underline',
						fontSize: 12,
						color: '#0563c1',
						fontWeight: 'bold',
					}}
				>
					{item.articleChina}
				</a>
			),
			color: '#93c47d',
			textColor: '#000',
		},
		{
			key: '6',
			name: (
				<i
					style={{
						display: '-webkit-box',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 1,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'normal',
					}}
				>
					Маржинальность артикула
				</i>
			),
			color: '#b6d7a8',
			textColor: '#000',
		},
		{
			key: '6',
			name: (
				<i
					style={{
						display: '-webkit-box',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 1,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'normal',
					}}
				>
					Цена без СПП
				</i>
			),
			color: '#d0e0e3',
			textColor: '#000',
		},
		{
			key: '7',
			name: <strong>Заказы</strong>,
			color: '#d0e0e3',
			textColor: '#000',
		},
		{
			key: '8',
			name: <i>Корзины</i>,
			color: '#d0e0e3',
			textColor: '#000',
		},
		{
			key: '9',
			name: (
				<i
					style={{
						display: '-webkit-box',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 1,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'normal',
					}}
				>
					Показы (реклама)
				</i>
			),
			color: '#d0e0e3',
			textColor: '#000',
		},
		{
			key: '10',
			name: (
				<i
					style={{
						display: '-webkit-box',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 1,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'normal',
					}}
				>
					Затраты (реклама)
				</i>
			),
			color: '#d0e0e3',
			textColor: '#000',
		},
		{
			key: '11',
			name: (
				<i
					style={{
						display: '-webkit-box',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 1,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'normal',
					}}
				>
					Клики (реклама)
				</i>
			),
			color: '#d0e0e3',
			textColor: '#000',
		},
		{
			key: '12',
			name: (
				<i
					style={{
						display: '-webkit-box',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 1,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'normal',
					}}
				>
					{' '}
					Все переходы
				</i>
			),
			color: '#d0e0e3',
			textColor: '#000',
		},
		{
			key: '13',
			name: (
				<i>
					<b>CTR</b>
				</i>
			),
			color: '#d0e0e3',
			textColor: '#000',
		},
		{
			key: '14',
			name: (
				<i>
					<b>CR1 Корзина</b>
				</i>
			),
			color: '#d0e0e3',
			textColor: '#000',
		},
		{
			key: '15',
			name: (
				<i>
					<b>CR2 Заказ</b>
				</i>
			),
			color: '#d0e0e3',
			textColor: '#000',
		},
		{
			key: '16',
			name: (
				<i>
					<b>CPC</b>
				</i>
			),
			color: '#d0e0e3',
			textColor: '#000',
		},
		{
			key: '17',
			name: (
				<i>
					<b>CPO</b>
				</i>
			),
			color: '#d0e0e3',
			textColor: '#000',
		},
		{
			key: '18',
			name: (
				<i>
					<b>ДРР (заказы)</b>
				</i>
			),
			color: '#d9d9d9',
			textColor: '#000',
		},
		{
			key: '19',
			name: (
				<i>
					<b>ДРР (выкупы)</b>
				</i>
			),
			color: '#b7b7b7',
			textColor: '#000',
		},
	]

	const tableFiller = [
		{
			key: '1',
			name: '',
			color: '#a4089f',
			textColor: '#fff',
		},
		{
			key: '2',
			name: '',
			color: '#a4089f',
			textColor: '#fff',
		},
		{
			key: '3',
			name: '',
			color: '#741b47',
			textColor: '#fff',
		},
		{
			key: '4',
			name: '',
			color: '#4c1130',
			textColor: '#fff',
		},
		{
			key: '7',
			name: '',
			color: '#434343',
			textColor: '#fff',
		},
		{
			key: '6',
			name: '',
			color: '#93c47d',
			textColor: '#000',
			article: item.wbArticle,
			price: item.price,
			redemption: item?.buyout ? `${item.buyout}%` : '',
			profit: item.profit.toFixed(),
		},
		{
			key: '5',
			name: '',
			color: '#b6d7a8',
			textColor: '#000',
		},
	]

	const StatisticsRow = ({ data, index }: any) => {
		return (
			<div className={`analytics-row ${index % 2 === 0 ? '' : 'gray'}`}>
				{data.map((col: any, index: number) => (
					<StatisticsTableCol
						key={index}
						title={col?.title}
						text={col?.text}
						width={col?.width}
						backgroundColor={col?.backgroundColor}
						icon={col?.icon}
						isShowHover={col?.isShowHover}
						hoverData={col?.hoverData}
					/>
				))}
			</div>
		)
	}

	return (
		<Row
			className={'analytics-row-container'}
			gutter={1}
			style={{
				display: 'flex',
				flexWrap: 'nowrap',
				maxWidth: '100%',
				overflow: 'hidden',
				minWidth: 250,
				background: '#434343',
				padding: 0,
				marginLeft: 'unset',
				marginRight: 'unset',
			}}
		>
			<Col
				style={{
					// padding: '9px 0px',
					width: '100%',
					maxWidth: 250,
					minWidth: 250,
					padding: 'unset',
				}}
			>
				<TableItems data={tableVerticalHeaders} />
			</Col>
			<Col
				className='product-card'
				style={{
					padding: 'unset',
				}}
			>
				<TableOnCard data={tableFiller} />
				<Row className='product-details'>
					<Col className='product-image'>
						<a href={item?.linkOnSite} target='_blank' rel='noreferrer'>
							{item.photoUrl ? (
								<img
									src={item.photoUrl}
									alt=''
									className='product-photo'
									onError={e => {
										e.currentTarget.onerror = null
										e.currentTarget.src = ''
										e.currentTarget.style.display = 'none'
										const noImage = e.currentTarget
											.nextElementSibling as HTMLElement
										if (noImage) noImage.style.display = 'block'
									}}
								/>
							) : null}
							<NoImage
								className='product-photo'
								style={{
									width: 280,
									display: item?.photoUrl ? 'none' : 'block',
								}}
							/>
						</a>
					</Col>
					<Col className='product-info'>
						<div className='info-row border-bottom'>
							<div className='analytics-col border-right'>
								<div
									className='status highlight yellow'
									style={{
										background:
											productStatusOptions.find(
												option => option.value === item.status?.productStatus
											)?.color || 'unset',
									}}
								>
									<SelectValue
										value={item.status?.productStatus}
										onChange={productStatus =>
											addOrChangeProductStatus({
												product_id: item.productId,
												actionStatus: item?.status?.actionStatus
													? item.status?.actionStatus
													: '',
												productStatus: productStatus.toString(),
											})
										}
										options={productStatusOptions}
									/>
								</div>
								<div
									className='action highlight grey'
									style={{
										background:
											actionStatusOptions.find(
												option => option.value === item.status?.actionStatus
											)?.color || 'unset',
									}}
								>
									<SelectValue
										value={item.status?.actionStatus}
										onChange={actionStatus =>
											addOrChangeProductStatus({
												product_id: item.productId,
												actionStatus: actionStatus.toString(),
												productStatus: item?.status?.productStatus
													? item.status?.productStatus
													: '',
											})
										}
										options={actionStatusOptions}
									/>
								</div>
							</div>
							<div className='analytics-col centered-section'>
								<span className='comments'>
									<ChangeValue
										value={item.comments?.comment || 'Комментарий'}
										onChange={(value: string | number) =>
											addComment({
												product_id: item.productId,
												comment: value.toString(),
											})
										}
									/>
								</span>
							</div>
						</div>
						<div className='info-row'>
							<div className='analytics-col border-right'>
								<div className='empty-row' />
							</div>
							<div className='analytics-col'></div>
						</div>
						<div className='info-row border-top border-bottom'>
							<div className='analytics-col border-right'>
								<div className='stock-data '>Итого</div>
								<div className='stock-data '>по складам</div>
								<div className='stock-data'>{`${item.totalStorages} шт`}</div>
							</div>
							<div className='analytics-col'>
								<div className='order-data transparent half-row'>
									<MinMax
										title='заказов'
										min={item?.plannedOrders?.min || 0}
										max={item?.plannedOrders?.max || 0}
										onChange={({ min, max }) =>
											planOrders({
												product_id: item.productId,
												min: min,
												max: max,
											})
										}
									/>
								</div>
								<div className='boost-data transparent half-row'>
									x
									{item?.margin &&
										item?.margin.toFixed(2).toString().replace('.', ',')}
								</div>
							</div>
						</div>
						<div className='info-row'>
							<div className='analytics-col border-right'>
								<div className='empty-row' />
							</div>
							<div className='analytics-col'></div>
						</div>
						<div className='info-row border-top border-bottom'>
							<div className='analytics-col border-right'>
								<div className='stock-duration light-yellow'>хватит на</div>
							</div>
							<div className='analytics-col'>
								<div className='stock-duration underline  light-yellow'>
									{item?.leftoverDays && item.leftoverDays.toFixed(2)}
								</div>
							</div>
						</div>
						<div className='info-row'>
							<div className='analytics-col border-right'>
								<div className='empty-row' />
							</div>
							<div className='analytics-col'></div>
						</div>
						<div className='ads-data'>
							{item?.adsStatuses ? (
								item.adsStatuses.map(
									(
										ad: {
											id: number
											adType: string
											adRunStatus: 'active' | 'disabled' | 'paused' | 'unset'
										},
										index: number
									) => (
										<div className='ads-data-row' key={ad.id}>
											<span className='ads-status border-right'>
												<SelectValue
													value={ad.adRunStatus}
													onChange={value =>
														adStatus({
															product_id: item.productId,
															adType: ad.adType,
															adRunStatus: value.toString(),
														})
													}
													options={statusOptions}
												/>

												{/* {ad?.adRunStatus
													? statusConfig[ad.adRunStatus].emoji
													: '🚫'} */}
											</span>
											<span className='ads-type'>{ad.adType}</span>
										</div>
									)
								)
							) : (
								<div className='ads-data-row'>
									<span className='ads-status border-right'>🚫</span>
									<span className='ads-type'>Нет данных о рекламе</span>;
								</div>
							)}
						</div>
					</Col>
				</Row>
			</Col>

			<Col
				// className={'ads-statistics-right-wrapper'}
				style={{
					overflowX: 'auto',
					// padding: '9px 0px',
					width: '100%',
					padding: 'unset',
				}}
			>
				<DynamicTable
					columnsData={columnsData}
					data={item}
					addDateComment={addDateComment}
				/>
			</Col>
		</Row>
	)
}

{
	/* <div
				className={'analytics-col-text'}
			>
				<span>{renderTableText(text)}</span>
			</div> */
}

{
	/* {itemData?.map((item: any, index: number) => (
							<StatisticsTableCol
								key={index}
								title={item?.title}
								text={item?.text}
								width={item?.width}
								backgroundColor={item?.backgroundColor}
								icon={item?.icon}
								isShowHover={item?.isShowHover}
								hoverData={item?.hoverData}
							/>
						))} */
}
