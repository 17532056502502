import { formatDate } from 'utils/formatDate'
import { api } from '../index'
import { message } from 'antd'

interface ISetPrices {
	id: number
	productId: number
	linkOnSite: string
	photoUrl: string
	newPrice: number
	newFullPrice: number
	newDiscount: number
	currentPrice: number
	currentFullPrice: number
	currentDiscount: number
	createdAt: string
	updatedAt: string
	deletedAt: string
}

export async function getData(
	dataType?: 'summary' | 'details' | 'buyouts',
	sellers?: any[],
	search?: string,
	available?: boolean | null,
	products?: any[],
	start_date?: string | null,
	end_date?: string | null,
	currentPageSize?: string,
	page?: string | number
) {
	try {
		const params = new URLSearchParams()

		if (sellers && sellers.length > 0) {
			params.append('sellers', sellers?.map((item: any) => item?.id)?.join(','))
		}

		if (available !== null) {
			params.append('tracking', `${available}`)
		}
		if (products && products.length > 0) {
			params.append(
				'categories',
				products?.map((item: any) => item?.id)?.join(',')
			)
		}
		if (page) {
			params.append('page', `${page}`)
		}

		if (currentPageSize) {
			params.append('limit', currentPageSize)
		}

		if (search) {
			params.append('search', search)
		}
		if (start_date) {
			params.append('start_date', start_date)
		}
		if (end_date) {
			params.append('end_date', end_date)
		}

		const response = await api.get<any>(
			`unit-economics/${dataType}?${params.toString()}`
		)
		return response.data.data
	} catch (error: any) {
		console.error(`Error fetching data:`, error)
		message.error(`Failed to fetch data. Please try again.`)
	}
}

export async function setBuyout(
	article: string,
	self_buyout_photo: number,
	self_buyout_text_count: number,
	send_to_clients_for_buyout: number,
	start_date: string,
	end_date: string,
	payment_date: string
) {
	try {
		const params = new URLSearchParams()

		if (article) {
			params.append('article', article)
		}
		if (self_buyout_photo) {
			params.append('self_buyout_photo', self_buyout_photo.toString())
		}
		if (self_buyout_text_count) {
			params.append('self_buyout_text_count', self_buyout_text_count.toString())
		}
		if (send_to_clients_for_buyout) {
			params.append(
				'send_to_clients_for_buyout',
				send_to_clients_for_buyout.toString()
			)
		}
		if (start_date) {
			params.append('start_date', start_date)
		}
		if (end_date) {
			params.append('end_date', end_date)
		}
		if (payment_date) {
			params.append('payment_date', payment_date)
		}

		const response = await api.post<any>(
			`unit-economics/buyouts?${params.toString()}`
		)
		return response.data.data
	} catch (error: any) {
		console.error(`Error fetching data:`, error)
		message.error(`Failed to fetch data. Please try again.`)
	}
}

export async function addFileToPrices(
	file: any,
	type: 'import-prices' | 'import-min-prices' | 'import-planned-prices'
) {
	const formData = new FormData()
	formData.append('file', file)

	const params = new URLSearchParams()

	const response = await api.post<any>(`/prices/${type}`, formData, {
		params,
	})
	return response.data
}

export async function setPrices(prices: ISetPrices[]) {
	console.log('Исходные данные:', prices)

	const response = await api.post<any>(`/prices/set-prices`, prices, {
		headers: {
			'Content-Type': 'application/json',
		},
	})

	return response.data
}
export async function editSummary(
	id: number,
	appear_date?: string,
	buy_count_cycle?: number,
	ads_spend_balance?: number,
	ads_spend_bill?: number,
	ads_spend_bonus?: number,
	marketing_spends?: number,
	product_buys_cycle?: number,
	fulfillment_price?: number,
	support_cost?: number,
	tvt_cost?: number,
	tvt_payment_date?: string
) {
	try {
		const params = new URLSearchParams()

		if (id !== null) {
			params.append('id', id.toString())
		}
		if (appear_date != null) {
			params.append('appear_date', appear_date.toString())
		}
		if (buy_count_cycle != null) {
			params.append('buy_count_cycle', buy_count_cycle.toString())
		}
		if (ads_spend_balance != null) {
			params.append('ads_spend_balance', ads_spend_balance.toString())
		}
		if (ads_spend_bill != null) {
			params.append('ads_spend_bill', ads_spend_bill.toString())
		}
		if (ads_spend_bonus != null) {
			params.append('ads_spend_bonus', ads_spend_bonus.toString())
		}
		if (marketing_spends != null) {
			params.append('marketing_spends', marketing_spends.toString())
		}
		if (product_buys_cycle != null) {
			params.append('product_buys_cycle', product_buys_cycle.toString())
		}
		if (fulfillment_price != null) {
			params.append('fulfillment_price', fulfillment_price.toString())
		}
		if (support_cost != null) {
			params.append('support_cost', support_cost.toString())
		}
		if (tvt_cost != null) {
			params.append('tvt_cost', tvt_cost.toString())
		}
		if (tvt_payment_date != null) {
			params.append('tvt_payment_date', tvt_payment_date.toString())
		}

		const response = await api.patch<any>(
			`/unit-economics/summary?${params.toString()}`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)

		return response.data
	} catch (error: any) {
		console.error(`Error fetching data:`, error)
		message.error(`Не удалось изменить минимальную цену. Попробуйте еще раз.`)
	}
}

export async function editBuyout(
	id: number,
	article?: string,
	start_date?: string,
	end_date?: string,
	self_buyout_photo?: number,
	self_buyout_text_count?: number,
	self_buyout_cost?: number,
	send_to_clients_for_buyout?: number,
	payment_date?: string
) {
	try {
		const params = new URLSearchParams()

		if (id !== null) {
			params.append('id', id.toString())
		}
		if (article != null) {
			params.append('article', article)
		}
		if (start_date != null) {
			params.append('start_date', start_date)
		}
		if (end_date != null) {
			params.append('end_date', end_date)
		}
		if (self_buyout_photo != null) {
			params.append('self_buyout_photo', self_buyout_photo.toString())
		}
		if (self_buyout_text_count != null) {
			params.append('self_buyout_text_count', self_buyout_text_count.toString())
		}
		if (self_buyout_cost != null) {
			params.append('self_buyout_cost', self_buyout_cost.toString())
		}
		if (send_to_clients_for_buyout != null) {
			params.append(
				'send_to_clients_for_buyout',
				send_to_clients_for_buyout.toString()
			)
		}
		if (payment_date != null) {
			params.append('payment_date', payment_date)
		}
		const response = await api.patch<any>(
			`/unit-economics/buyouts?${params.toString()}`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)

		return response.data
	} catch (error: any) {
		console.error(`Error fetching data:`, error)
		message.error(`Не удалось изменить минимальную цену. Попробуйте еще раз.`)
	}
}

export async function downloadAllPrices() {
	// promotion?: string | number | null
	// sellers?: any[],
	// search?: string,
	// products?: any[],
	// available?: boolean | null,
	// participating?: boolean | null,
	// setInAction?: boolean | null
	try {
		const params = new URLSearchParams()

		// if (sellers && sellers?.length > 0) {
		// 	params.append('sellers', sellers.map((item: any) => item?.id).join(','))
		// }

		// if (promotion) {
		// 	params.append('promotions', `${promotion}`)
		// }

		// if (search) {
		// 	params.append('search', search)
		// }

		// if (products && products?.length > 0) {
		// 	params.append(
		// 		'categories',
		// 		products.map((item: any) => item?.id).join(',')
		// 	)
		// }

		// if (available !== null) {
		// 	params.append('tracking', `${available}`)
		// }

		// if (participating !== null) {
		// 	params.append('in_action', `${participating}`)
		// }

		// if (setInAction !== null) {
		// 	params.append('set_in_action', `${setInAction}`)
		// }

		const queryString = params.toString().replace(/\+/g, '%20')
		const response = await api.get<any>(
			`prices/download-all-prices${queryString ? `?${queryString}` : ''}`,
			{
				responseType: 'blob',
			}
		)
		return response.data
	} catch (error: any) {
		console.error(`Error downloading nomenclatures excel:`, error)
		message.error(`Критическая ошибка выгрузки. Попробуйте позже`)
		throw error
	}
}

export async function deleteBuyout(id: number) {
	try {
		const params = new URLSearchParams()

		if (id) {
			params.append('id', id.toString())
		}

		const response = await api.delete<any>(
			`unit-economics/buyout?${params.toString()}`
		)
		return response.data.data
	} catch (error: any) {
		console.error(`Error fetching data:`, error)
		message.error(`Failed to fetch data. Please try again.`)
	}
}
