import { useState } from 'react'
import { Input, Button, Modal, DatePicker } from 'antd'
import {
	CloseCircleOutlined,
	EditOutlined,
	SaveOutlined,
} from '@ant-design/icons'
import ModalHeader from 'features/shared/ModalHeader'
import dayjs from 'dayjs'

const EditValue = ({
	value,
	onValueChange,
	type,
}: {
	value?: number | string
	onValueChange?: (newAfter: number | string) => void
	type: 'date' | 'number' | 'string' | 'invisible' | 'count'
}) => {
	const [isEditing, setIsEditing] = useState(false)
	const [editedAfter, setEditedAfter] = useState(value)

	const handleEdit = () => {
		setIsEditing(true)
	}

	const handleSave = () => {
		setIsEditing(false)
		if (onValueChange && editedAfter !== undefined) {
			onValueChange(editedAfter)
		}
	}

	const handleCancel = () => {
		setIsEditing(false)
		setEditedAfter(value)
	}

	const onClose = () => {
		setIsEditing(false)
	}

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				gap: 8,
			}}
		>
			{value != null && (
				<>
					{onValueChange && isEditing && (
						<Modal open={isEditing} footer={null} onCancel={onClose}>
							<div className={'modal-wrapper'} style={{ padding: '30px' }}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-end',
										justifyContent: 'center',
										gap: 16,
										marginTop: 16,
									}}
								>
									{type === 'date' ? (
										<DatePicker
											style={{ width: '100%' }}
											placeholder='Выберите дату'
											format='DD.MM.YYYY'
											onChange={date =>
												setEditedAfter(date ? date.format('DD.MM.YYYY') : '')
											}
										/>
									) : (
										<Input
											type={
												type === 'number' || type === 'count'
													? 'number'
													: 'text'
											}
											value={editedAfter}
											onChange={e => setEditedAfter(e.target.value)}
										/>
									)}
									<div style={{ display: 'flex', gap: 4 }}>
										<Button type='primary' onClick={handleSave}>
											<SaveOutlined /> Сохранить
										</Button>
										<Button type='text' onClick={handleCancel}>
											<CloseCircleOutlined /> Отменить
										</Button>
									</div>
								</div>
							</div>
						</Modal>
					)}
					<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
						{type !== 'invisible' && (
							<span>
								{type === 'date'
									? dayjs(value).format('DD.MM.YYYY')
									: type === 'number'
									? Number(value).toFixed(2)
									: value}
							</span>
						)}
						{onValueChange && (
							<EditOutlined
								onClick={handleEdit}
								style={{ cursor: 'pointer' }}
							/>
						)}
					</div>
				</>
			)}
		</div>
	)
}

export default EditValue
