import React, { FC, useEffect, useState } from 'react'
import { Button, Col, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import AddFileModal from '../modals/AddFileModal'
import UploadBuyout from '../modals/UploadBuyout'

interface IDownload {
	title: string
	setData: (data: any) => void
}
const HandleUploadBuyout: FC<IDownload> = ({ title, setData }) => {
	const [isOpen, setIsOpen] = useState(false)

	const onClick = () => {
		setIsOpen(true)
	}

	return (
		<>
			<Button
				onClick={onClick}
				type='primary'
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%',
				}}
			>
				{title}
			</Button>
			<UploadBuyout
				title={title}
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false)
				}}
				setData={setData}
			/>
		</>
	)
}

export default HandleUploadBuyout
