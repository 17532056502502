import {Avatar, Button, Col, Form, Input, Row} from "antd";
import {
    UserOutlined,
    ContactsOutlined,
    MobileOutlined,
    LockOutlined,
} from "@ant-design/icons";
import Icon from "@ant-design/icons";
import {ReactComponent as telegramIcon} from "assets/images/telegram-icon.svg";
import avatarImage from "assets/images/avatar.png";
import UsersTable from "features/users/UsersTable";
import React, {useEffect, useRef, useState} from "react";
import {useAuthStore} from "features/auth/hooks/useAuthStore";
import {useUserUpdate} from "features/users/hooks/useUserUpdate";
import {useUserUpdateAvatar} from "../../features/users/hooks/useUserUpdateAvatar";
import useCurrentUserData from "../../features/users/hooks/useCurrentUserData";
import {MaskedInput} from "antd-mask-input";
import InputMask from 'react-input-mask';

export default function AccountPage() {

    const {state} = useAuthStore();
    const isVisibleBottomTabs =
			state?.user?.role?.name === 'Администратор' ||
			state?.user?.role?.name === 'СуперАдмин'
    const [form] = Form.useForm();

    const [isPhoneError, setIsPhoneError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);

    const {
        handleUpdate,
        isError,
        errorMessage,
        isLoading,
        setErrorMessage,
    } = useUserUpdate(state.user.id);

    const {
        handleUpdateCurrentAvatar,
        updateLoadingAvatar,
        isError: isErrorUpdateAvatar,
        errorMessage: isErrorMessageUpdateAvatar,
        isSuccess: isSuccessUpdateAvatar
    } = useUserUpdateAvatar();

    const {
        user,
        isLoading: isLoadingCurrentUser
    } = useCurrentUserData();

    const [avatarPreview, setAvatarPreview] = useState("");
    const [avatarKey, setAvatarKey] = useState(0);

    useEffect(() => {
        if (state.user) {
            form.setFieldsValue({
                firstName: state.user.firstName,
                lastName: state.user.lastName,
                email: state.user.email,
                password: "",
                tg: state.user.tg,
                phone: state.user.phone,
                photo: state.user.photo,
            });
            setAvatarPreview(`${process.env.REACT_APP_API_URL}storage/${state?.user?.photo}`);

        }
    }, [state.user]);

    useEffect(() => {
        if (isSuccessUpdateAvatar) {
            setAvatarPreview(`${process.env.REACT_APP_API_URL}storage/${user?.photo}`);
        }
    }, [isSuccessUpdateAvatar])


    const onUpdate = async (values: any) => {
        //sending only updated values

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (values.phone.length < 18) {
            setIsPhoneError(true);
            return
        }

        if (!emailRegex.test(values.email)) {
            setIsEmailError(true);
            return
        }

        let updatedValues = {...values};

        if (updatedValues.roleId === state.user.role.id.toString()) {
            delete updatedValues.roleId;
        }
        if (updatedValues.firstName === state.user.firstName) {
            delete updatedValues.firstName;
        }
        if (updatedValues.lastName === state.user.lastName) {
            delete updatedValues.lastName;
        }
        if (updatedValues.phone === state.user.phone) {
            delete updatedValues.phone;
        }
        if (updatedValues.tg === state.user.tg) {
            delete updatedValues.tg;
        }
        if (updatedValues.login === state.user.login) {
            delete updatedValues.login;
        }
        if (updatedValues.email === state.user.email) {
            delete updatedValues.email;
        }
        if (!updatedValues.password) {
            delete updatedValues.password;
        }

        if (Object.keys(updatedValues).length !== 0) {
            handleUpdate(updatedValues);
        }
    };

    const fileRef = useRef<HTMLInputElement>(null);

    const handleFileChange = async (e: any) => {
        await handleUpdateCurrentAvatar(e.target.files[0])
        await setAvatarKey(prevKey => prevKey + 1);
    };


    return (
        <div style={{padding: "10px 20px 0px 20px"}}>
            <Row justify={"space-between"}>
                <Col
                    className={"container"}
                    style={{paddingBottom: "12px", width: "74%"}}
                >
                    <div className={"title-mini"}>Профиль</div>
                    <div
                        className="account-input-wrapper"
                        style={{padding: "10px 24px"}}
                    >
                        <Form
                            form={form}
                            onFinish={(values) => onUpdate(values)}
                        >
                            <Row gutter={17}>
                                <Col span={12}>
                                    <Form.Item
                                        name="email"
                                        rules={[{required: true, message: 'Пожалуйста введите почту'}]}
                                        validateStatus={isEmailError ? 'error' : ''}
                                        help={isEmailError ? 'Пожалуйста введите корректную почту' : null}
                                    >
                                        <Input
                                            onFocus={() => setIsEmailError(false)}
                                            prefix={
                                                <UserOutlined
                                                    style={{color: "#4C78EE"}}
                                                />
                                            }
                                            style={{
                                                padding: "5px !important",
                                            }}
                                            placeholder={"E-mail"}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="firstName">
                                        <Input
                                            prefix={
                                                <ContactsOutlined
                                                    style={{color: "#4C78EE"}}
                                                />
                                            }
                                            placeholder={"Имя"}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={17} style={{marginTop: "14px"}}>
                                <Col span={12}>
                                    <Form.Item
                                        name="phone"
                                        rules={[{required: true, message: 'Пожалуйста введите номер'}]}
                                        validateStatus={isPhoneError ? 'error' : ''}
                                        help={isPhoneError ? 'Пожалуйста введите корректный номер' : null}
                                    >
                                        <InputMask
                                            mask="+7 (999) 999-99-99"
                                            maskChar=""
                                            onFocus={() => setIsPhoneError(false)}
                                        >
                                            {(inputProps: any) => (
                                                <Input
                                                    {...inputProps}
                                                    prefix={<MobileOutlined style={{color: '#4C78EE'}}/>}
                                                    placeholder="Phone number"
                                                />
                                            )}
                                        </InputMask>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="lastName">
                                        <Input
                                            prefix={
                                                <ContactsOutlined
                                                    style={{color: "#4C78EE"}}
                                                />
                                            }
                                            placeholder={"Фамилия"}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={17} style={{marginTop: "14px"}}>
                                <Col span={12}>
                                    <Form.Item name="tg">
                                        <InputMask
                                            mask="@*********************************"
                                            maskChar=""
                                        >
                                            {(inputProps: any) => (
                                                <Input
                                                    {...inputProps}
                                                    prefix={<Icon component={telegramIcon}/>}
                                                    placeholder={"Telegram"}
                                                />
                                            )}
                                        </InputMask>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="password">
                                        <Input.Password
                                            prefix={
                                                <LockOutlined
                                                    style={{color: "#4C78EE"}}
                                                />
                                            }
                                            visibilityToggle={false}
                                            placeholder={"Пароль"}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify={"end"}>
                                <Col>
                                    <Button
                                        className={"button"}
                                        style={{
                                            marginTop: "14px",
                                            fontSize: "12px",
                                            width: "211px",
                                        }}
                                        type={"primary"}
                                        htmlType={"submit"}
                                        loading={isLoading}
                                    >
                                        Обновить информацию
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                        {isError && (
                            <div
                                className={"ant-form-item-explain-error"}
                                style={{color: "#ff4d4f"}}
                            >
                                {errorMessage}
                            </div>
                        )}
                    </div>
                </Col>
                <Col style={{width: "25%"}} className={"container"}>
                    <div className={"title-mini"}>Фото аватара</div>
                    <div className={"avatar-wrapper"}>
                        <div style={{
                            marginTop: "20px",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 auto'
                        }}>
                            <Avatar src={avatarPreview} size={136} key={avatarKey}/>
                            {" "}
                            <Button
                                style={{
                                    marginTop: 10,
                                }}
                                loading={updateLoadingAvatar}
                                className={"avatar-edit-button"}
                                type={"default"}
                                onClick={() => fileRef.current!.click()}
                            >
                                Изменить
                            </Button>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{display: "none"}}
                                ref={fileRef}
                            />
                        </div>
                    </div>
                </Col>
            </Row>

            {isVisibleBottomTabs &&
            <UsersTable/>
            }
        </div>
    );
}
