import axios from "axios";
import {
	getToken,
	getRefreshToken,
	setToken,
	setRefreshToken,
	removeToken,
	removeRefreshToken,
} from 'utils/tokenStorage'
import { refresh as refreshTokenRequest } from './auth'
import * as auth from "./auth";
import * as user from "./user";
import * as financialResult from "./financial-result";
import * as role from "./role";
import * as seller from "./seller";
import * as analytics from "./analytics";
import * as categories from "./categories";
import * as sizes from "./sizes";
import * as advertisingMirror from "./advertisingMirror";
import * as product from "./product";
import * as analyticUser from "./analyticUser";
import * as keyWords from "./keyWords";
import * as coordinatorUser from "./coordinatorUser";
import * as expenses from "./expenses";
import * as storage from "./storage";
import * as operatingExpenses from "./operatingExpenses";
import * as costPrice from "./costPrice";
import * as documentMirror from "./documentMirror";
import * as questionsAndAnswers from "./questionsAndAnswers";
import * as chat from "./chat";
import * as shipments from "./shipments";
import * as laboratory from "./laboratory";
import * as settingsMirror from'./settingsMirror';
import * as actions from'./actions';
import * as prices from'./prices';
import * as unitEconomics from'./unitEconomics';

export const createApi = () => {
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Access-Control-Allow-Credentials": true,
            Accept: "application/json, application/x-www-form-urlencoded",
        },
    });

    instance.interceptors.request.use((config) => {
        const token = getToken();
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    });

    instance.interceptors.response.use(
        (res) => res,
        async (error) => {
            const originalRequest = error.config;

            if (error.response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const refreshToken = getRefreshToken();

                if (refreshToken) {
                    try {
                        const newTokenData = await refreshTokenRequest(refreshToken);

                        if (newTokenData) {
                            setToken(newTokenData.access_token);
                            setRefreshToken(newTokenData.refresh_token);
                            originalRequest.headers["Authorization"] = `Bearer ${newTokenData.access_token}`;
                            return instance(originalRequest);
                        }
                    } catch (refreshError) {
                        console.error("Token refresh failed:", refreshError);
                    }
                }
                removeToken(); 
                removeRefreshToken();
                window.location.reload();
            }

            return Promise.reject(error);
        }
    );

    return instance;
};

export const backend = {
    auth,
    user,
    analyticUser,
    coordinatorUser,
    keyWords,
    financialResult,
    role,
    seller,
    analytics,
    categories,
    sizes,
    product,
    advertisingMirror,
    expenses,
    storage,
    operatingExpenses,
    costPrice,
    questionsAndAnswers,
    chat,
    documentMirror,
    shipments,
    laboratory,
    settingsMirror,
    actions,
    prices,
    unitEconomics
};
