import React, { useState, useEffect, useRef } from 'react'
import {
	Row,
	Col,
	Input,
	Select,
	Button,
	Table,
	Dropdown,
	Checkbox,
	DatePicker,
	Modal,
	Pagination,
	message,
	Spin,
} from 'antd'
import { ReactComponent as SelectIconComponent } from '../../../../assets/images/select-icon.svg'
import useAdStats from '../../hooks/advertisingStatistics/useAdStats'
import Icon, { LoadingOutlined, MoreOutlined } from '@ant-design/icons'
import IssuingPositionsItem from '../../components/issuingPositions/issuingPositionsItem'
import { ReactComponent as rangePickerArrow } from '../../../../assets/images/rangepicker-arrow.svg'
import dummy_data from './data.json'
import { AdvertisingStatisticsItem } from '../../components/advertisingStatistics/AdvertisingStatisticsItem'
import { DownloadExcel } from 'features/advertising-mirror/components/advertisingStatistics/DownloadExcel'
import { PickSeller } from 'features/advertising-mirror/components/advertisingStatistics/PickSellers'
import { PickIsAvailable } from 'features/advertising-mirror/components/advertisingStatistics/PickIsAvailable'
import { UploadExcel } from 'features/advertising-mirror/components/advertisingStatistics/UploadExcel'

const { Option } = Select
const { RangePicker } = DatePicker
export const AdvertisingStatistics = () => {
	const [data, setData] = useState<null | any>(null)

	const {
		statsData,
		currentPage,
		setCurrentPage,
		currentPageSize,
		dateStart,
		dateEnd,
		setCurrentPageSize,
		selectedSeller,
		handleRangeChangeTable,
		getProductsFilterItems,
		sellers,
		setSearchSellers,
		setSelectedSeller,
		searchArticle,
		setSearchArticle,
		isLoading,
		getSellersFilterItems,
		setDataType,
		selectedAvailableType,
		setSelectedAvailableType,
		addComment,
		planOrders,
		addOrChangeProductStatus,
		adStatus,
		downloadAdsStatsExcel,
		addDateComment,
	} = useAdStats()

	useEffect(() => {
		if (statsData) {
			// setPromotions(decodedSegment)
			// setPromotions('44%2C%2064')
			setData(statsData)
			console.log(statsData)
		}
	}, [currentPage, statsData])

	const onShowSizeChange = (current: number | string, size: number | string) =>
		setCurrentPageSize(Number(size))

	console.log(data)

	const [downloadLoading, setDownloadLoading] = useState(false)

	const handleDownload = async () => {
		message.warning('Скачивание началось. Пожалуйста не закрывайте страницу.')
		setDownloadLoading(true)
		try {
			await downloadAdsStatsExcel()
		} finally {
			setDownloadLoading(false)
		}
	}

	return (
		<div>
			<Row gutter={6} align={'middle'}>
				<Col style={{ flexGrow: '1' }}>
					<Input
						value={searchArticle}
						onChange={(text: any) => setSearchArticle(text.target.value)}
						className='table-header-input'
						placeholder='Поиск по артикулу'
						size='large'
					/>
				</Col>
				<Col>
					<PickIsAvailable
						selectedAvailableType={selectedAvailableType}
						setSelectedAvailableType={setSelectedAvailableType}
					/>
				</Col>
				<Col style={{ width: '120px' }}>
					<PickSeller
						getSellersFilterItems={getSellersFilterItems}
						selectedSeller={selectedSeller}
					/>
				</Col>

				<Col style={{ width: '98px' }}>
					<Dropdown
						trigger={['click']}
						placement={'bottomRight'}
						menu={{ items: getProductsFilterItems() }}
						overlayClassName={'dropdown-border'}
					>
						<div className={'analytics-header-dropdown '}>
							<div>Предмет</div>
							<Icon
								component={SelectIconComponent}
								style={{ marginTop: '2px', fontSize: '10px' }}
							/>
						</div>
					</Dropdown>
				</Col>

				<Col style={{ width: '300px' }}>
					<RangePicker
						onChange={handleRangeChangeTable}
						value={[dateStart, dateEnd]}
						style={{
							backgroundColor: '#fff',
							height: 38,
							borderRadius: 0,
							borderColor: '#fff',
						}}
						format='DD.MM.YYYY'
						placeholder={['Начало', 'Конец']}
						separator={<Icon component={rangePickerArrow} />}
					/>
					{/*<DatePickerCustomed*/}
					{/*    pickerOpen={pickerOpen}*/}
					{/*    setPickerOpen={setPickerOpen}*/}
					{/*    dateStart={dateStart}*/}
					{/*    dateEnd={dateEnd}*/}
					{/*    handleRangeChangeTable={handleRangeChangeTable}*/}
					{/*/>*/}
				</Col>
				<Col>
					<UploadExcel title={'Обновить данные с кометы'} />
				</Col>
				<Col>
					<DownloadExcel
						handleDownload={handleDownload}
						isLoading={downloadLoading}
						title={'Выгрузить'}
					/>
				</Col>
			</Row>

			<div>
				<div className='issuing-positions-wrap'>
					{data && !isLoading ? (
						data?.data.map((item: any) => (
							<AdvertisingStatisticsItem
								item={item}
								addComment={addComment}
								planOrders={planOrders}
								addOrChangeProductStatus={addOrChangeProductStatus}
								adStatus={adStatus}
								addDateComment={addDateComment}
							/>
						))
					) : (
						<div style={{ minHeight: '70vh' }}>
							<LoadingOutlined />
						</div>
					)}
				</div>
				<Pagination
					onChange={(page, pageSize): any => setCurrentPage(page)}
					defaultCurrent={1}
					current={currentPage}
					pageSize={Number(currentPageSize)}
					total={data?.total}
					style={{
						textAlign: 'center',
						padding: '16px 0',
					}}
					showSizeChanger={true}
					defaultPageSize={25}
					pageSizeOptions={[10, 25, 50, 100, 250, 500, 1000]}
					onShowSizeChange={onShowSizeChange}
				/>
			</div>
		</div>
	)
}
