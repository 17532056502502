import { useState } from 'react'
import { Input, Button, Modal, Col } from 'antd'
import {
	CloseCircleOutlined,
	EditOutlined,
	SaveOutlined,
} from '@ant-design/icons'
import ModalHeader from 'features/shared/ModalHeader'
import TitleWithTooltip from 'features/actions/components/TitleWithTooltip'

const ChangeValue = ({
	value,
	onChange,
	rows = 2,
	maxWidth = 'unset',
	maxHeight = 'unset',
	fontSize = 'inherit',
}: {
	value: number | string
	onChange: (value: number | string) => void
	rows?: number
	maxWidth?: string | number
	maxHeight?: string | number
	fontSize?: number | string
}) => {
	const [isEditing, setIsEditing] = useState(false)
	const [editedValue, setEditedValue] = useState(value)

	const handleEdit = () => {
		setIsEditing(true)
	}

	const handleSave = () => {
		setIsEditing(false)
		if (onChange && editedValue !== undefined) {
			onChange(editedValue)
		}
	}

	const handleCancel = () => {
		setIsEditing(false)
		setEditedValue(value)
	}

	const onClose = () => {
		setIsEditing(false)
	}

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				gap: 8,
			}}
		>
			{value != null && (
				<>
					{isEditing && (
						<Modal open={isEditing} footer={null} onCancel={onClose}>
							<div className={'modal-wrapper'} style={{ padding: '30px' }}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-end',
										justifyContent: 'center',
										gap: 16,
										marginTop: 16,
									}}
								>
									<Input
										type='text'
										value={editedValue}
										onChange={e => setEditedValue(e.target.value)}
									/>
									<div style={{ display: 'flex', gap: 4 }}>
										<Button type='primary' onClick={handleSave}>
											<SaveOutlined /> Сохранить
										</Button>
										<Button type='text' onClick={handleCancel}>
											<CloseCircleOutlined /> Отменить
										</Button>
									</div>
								</div>
							</div>
						</Modal>
					)}
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: 8,
							padding: 4,
							maxWidth: maxWidth,
							maxHeight: maxHeight,
							cursor: 'pointer',
						}}
						onClick={handleEdit}
					>
						<TitleWithTooltip
							title={value.toString()}
							rows={rows}
							fontSize={fontSize}
						/>
						{/* <EditOutlined style={{ cursor: 'pointer' }} /> */}
					</div>
				</>
			)}
		</div>
	)
}

export default ChangeValue
