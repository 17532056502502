import {useMutation, useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";
import useSizeList from "features/sizes/hooks/useSizeList";
import {Checkbox, Input, MenuProps, message} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as searchIcon} from "assets/images/search-icon.svg";
import useProductList from "../../product/hooks/useProductList";
import useSellerList from "../../sellers/hooks/useSellerList";
import dayjs from "dayjs";

export default function useAnalyticsData() {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [showMode, setShowMode] = useState<string>("articule");
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearchText] = useDebounce(searchText, 1000);
    const [selectedSizes, setSelectedSizes] = useState<Array<string>>([]);
    // const [spp, setSpp] = useState<string>("20");
    // const [debouncedSpp] = useDebounce(spp, 1000);
    const [selectedProducts, setSelectedProducts] = useState<Array<string>>([]);
    const [selectedSeller, setSelectedSeller] = useState<Array<string>>([]);

    const getLastEndOfWeek = (today: any = dayjs()) => {

        if (today.day() === 1 || today.day() === 2) {
            const previousWeek = today.subtract(2, 'week');
            return  previousWeek.endOf('week')
        } else {
            // Если сегодня не понедельник и не вторник, находим последнее воскресенье, двигаясь назад по дням
            return today.subtract(1, 'week').endOf('week')
        }
    };

    const getPreLastEndOfWeek = (today: any = dayjs()) => {

        if (today.day() === 1 || today.day() === 2) {
            const previousWeek = today.subtract(2, 'week');
            return previousWeek.startOf('week')
        } else {
            // Если сегодня не понедельник и не вторник, находим последнее воскресенье, двигаясь назад по дням
            return today.subtract(1, 'week').startOf('week')
        }
    }

    const [dateSortStart, setDateSortStart] = useState<any>(getPreLastEndOfWeek());
    const [dateSortEnd, setDateSortEnd] = useState<any>(getLastEndOfWeek());

    const {
        sizes,
        search: searchSize,
        setSearch: setSearchSize,
    } = useSizeList();

    const {
        products,
        search: searchProduct,
        setSearch: setSearchProduct,
        setNotEmptyMode
    } = useProductList();

    const {
        sellers,
        search: searchSellers,
        setSearch: setSearchSellers,
    } = useSellerList();

    const getProductsFilterItems = () => {
        if (!products) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        products?.forEach((item: any) => {
            const isDuplicate = selectedProducts.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{ display: "flex", gap: "10px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item.title}
                                checked={selectedProducts.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                                onChange={(e) => {
                                    let newSelectedProducts = [...selectedProducts];
                                    if (e.target.checked) {
                                        newSelectedProducts.push(item);
                                    } else {
                                        newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                                    }
                                    setSelectedProducts(newSelectedProducts);
                                }}
                            >
                                {item.title}
                            </Checkbox>
                        </div>
                    ),
                    key: `product-${item.id}`,
                });
            }
        });

        selectedProducts?.forEach((item: any) => {
            const isDuplicate = selectedProducts.some((productItem: any) => productItem?.id?.toString() === item?.id?.toString());

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{ display: "flex", gap: "10px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item.title}
                                checked={selectedProducts.some((productItems: any) => productItems?.id?.toString() === item?.id?.toString())}
                                onChange={(e) => {
                                    let newSelectedProducts = [...selectedProducts];
                                    if (e.target.checked) {
                                        newSelectedProducts.push(item);
                                    } else {
                                        newSelectedProducts = newSelectedProducts.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                                    }
                                    setSelectedProducts(newSelectedProducts);
                                }}
                            >
                                {item.title}
                            </Checkbox>
                        </div>
                    ),
                    key: `product-${item.id}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchProduct}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchProduct(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "product-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    const getSizeFilterItems = () => {
        if (!sizes) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        sizes?.slice(0, 10)?.forEach((item: any) => {
            const isDuplicate = selectedSizes.some((sizeItem: any) => sizeItem?.title === item?.title);

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{ display: "flex", gap: "10px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item.title}
                                checked={selectedSizes.some((sizeItem: any) => sizeItem?.title === item?.title)}
                                onChange={(e) => {
                                    let newSelectedSizes = [...selectedSizes];
                                    if (e.target.checked) {
                                        newSelectedSizes.push(item);
                                    } else {
                                        newSelectedSizes = newSelectedSizes.filter((el: any) => el?.title !== item?.title);
                                    }
                                    setSelectedSizes(newSelectedSizes);
                                }}
                            >
                                {item.title}
                            </Checkbox>
                        </div>
                    ),
                    key: `sizes-${item.title}`,
                });
            }
        });

        selectedSizes?.forEach((item: any) => {
            const isDuplicate = selectedSizes.some((sizeItem: any) => sizeItem?.id?.toString() === item?.id?.toString());

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{ display: "flex", gap: "10px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item.title}
                                checked={selectedSizes.some((sizeItem: any) => sizeItem?.title === item?.title)}
                                onChange={(e) => {
                                    let newSelectedSizes = [...selectedSizes];
                                    if (e.target.checked) {
                                        newSelectedSizes.push(item);
                                    } else {
                                        newSelectedSizes = newSelectedSizes.filter((el: any) => el?.title !== item?.title);
                                    }
                                    setSelectedSizes(newSelectedSizes);
                                }}
                            >
                                {item.title}
                            </Checkbox>
                        </div>
                    ),
                    key: `sizes-${item.title}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchSize}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchSize(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "sizes-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    const getSellersFilterItems = () => {
        if (!sellers) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        sellers?.forEach((item: any) => {
            const isDuplicate = selectedSeller.some((sellerItem: any) => sellerItem?.id.toString()=== item?.id.toString());

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{ display: "flex", gap: "10px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item?.company_name}
                                checked={selectedSeller.some((sellerItem: any) => sellerItem?.id.toString() === item?.id.toString())}
                                onChange={(e) => {
                                    let newSelectedSellers = [...selectedSeller];
                                    if (e.target.checked) {
                                        newSelectedSellers.push(item);
                                    } else {
                                        newSelectedSellers = newSelectedSellers.filter((el: any) => el?.id.toString() !== item?.id.toString());
                                    }
                                    setSelectedSeller(newSelectedSellers);
                                }}
                            >
                                {item?.company_name}
                            </Checkbox>
                        </div>
                    ),
                    key: `seller-${item.id.toString()}`,
                });
            }
        });

        selectedSeller?.forEach((item: any) => {
            const isDuplicate = selectedSeller.some((sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString());

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{ display: "flex", gap: "10px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item?.company_name}
                                checked={selectedSeller.some((sellerItem: any) => sellerItem?.id.toString() === item?.id.toString())}
                                onChange={(e) => {
                                    let newSelectedSellers = [...selectedSeller];
                                    if (e.target.checked) {
                                        newSelectedSellers.push(item);
                                    } else {
                                        newSelectedSellers = newSelectedSellers.filter((el: any) => el?.id.toString() !== item?.id.toString());
                                    }
                                    setSelectedSeller(newSelectedSellers);
                                }}
                            >
                                {item?.company_name}
                            </Checkbox>
                        </div>
                    ),
                    key: `seller-${item.id}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchSellers}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchSellers(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "sizes-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    const onChangeData = (dateStrings: any) => {
        setDateSortStart(dateStrings[0])
        setDateSortEnd(dateStrings[1])
    }

    const {data: analytics, ...options} = useQuery(
        [
            "ANALYTICS_DATA",
            currentPage,
            currentPageSize,
            showMode,
            debouncedSearchText,
            selectedSizes,
            selectedProducts,
            selectedSeller,
            dateSortStart,
            dateSortEnd,
        ],
        () =>
            backend.analytics.getAnalyticsData(
                currentPage.toString(),
                currentPageSize,
                showMode,
                debouncedSearchText,
                selectedSizes,
                selectedProducts,
                selectedSeller,
                dateSortStart.toString(),
                dateSortEnd.toString()
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );

    	const generateFilename = (): string => {
				const currentDate = dayjs().format('YYYY-MM-DD')
                const sellers = selectedSeller
									?.map((item: any) => item?.company_name)
									?.join(',')
				return `fin_report_new_${currentDate}_${sellers}.xlsx`
			}
            
			const [downloadLoading, setDownloadLoading] = useState(false)

			const handleDownloadAllPrices = useMutation(
				async () => {
					setDownloadLoading(true)
					return backend.financialResult.downloadNewReport(selectedSeller)
				},
				{
					onError: err => {
						console.error(`Failed to download data: ${err}`)
						message.error('Не удалось скачать Excel файл. Попробуйте еще раз.')
					},
					onSuccess: (data: Blob) => {
						const url = window.URL.createObjectURL(data)
						const link = document.createElement('a')
						link.href = url
						link.setAttribute('download', generateFilename())
						link.setAttribute('target', '_blank')
						document.body.appendChild(link)
						link.click()
						link.remove()
						message.success('Файл успешно загружен!')
					},
					onSettled: () => {
						setDownloadLoading(false)
					},
				}
			)


    return {
        analytics,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        showMode,
        setShowMode,
        searchText,
        setSearchText,
        selectedSizes,
        selectedProducts,
        selectedSeller,
        dateSortStart,
        dateSortEnd,
        onChangeData,
        getSizeFilterItems,
        getProductsFilterItems,
        getSellersFilterItems,
        handleDownloadAllPrices: handleDownloadAllPrices.mutate,
        downloadLoading,
        setDownloadLoading,
        ...options,
    };
}
