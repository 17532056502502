import { useQuery, useMutation, useQueryClient } from 'react-query'
import { backend } from 'api'
import React, { useEffect, useState } from 'react'
import { Checkbox, Input, MenuProps, message } from 'antd'
import useSellerList from 'features/sellers/hooks/useSellerList'
import { format } from 'path'
import { formatDate } from 'utils/formatDate'
import dayjs, { Dayjs } from 'dayjs'
import useProductList from 'features/product/hooks/useProductList'
import Icon from '@ant-design/icons'
import { ReactComponent as searchIcon } from 'assets/images/search-icon.svg'
import { useDebounce } from 'use-debounce'

export default function useUnitEconomicsData() {
	const queryClient = useQueryClient()
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [currentPageSize, setCurrentPageSize] = useState<string>('25')

	const [dataType, setDataType] = useState<'buyouts' | 'details' | 'summary'>(
		'summary'
	)
	const [unitEconomicsData, setUnitEconomicsData] = useState<any>(null)
	const [nomenclaturesData, setNomenclaturesData] = useState<any>(null)
	const [promotions, setPromotions] = useState<string[] | null>(null)
	const [searchText, setSearchText] = useState<string>('')
	const [selectedChangeType, setSelectedChangeType] = useState<string[] | null>(
		null
	)
	const [selectedSeenType, setSelectedSeenType] = useState<boolean | null>(null)

	const [debouncedSearchText] = useDebounce(searchText, 1000)

	const {
		sellers,
		search: searchSellers,
		setSearch: setSearchSellers,
	} = useSellerList()

	const [selectedSeller, setSelectedSeller] = useState<Array<string>>([])

	const [dateSortStart, setDateSortStart] = useState<Dayjs | null>(null)
	const [dateSortEnd, setDateSortEnd] = useState<Dayjs | null>(null)

	const onChangeDate = (dates: [Dayjs | null, Dayjs | null] | null) => {
		if (dates) {
			setDateSortStart(dates[0])
			setDateSortEnd(dates[1])
		} else {
			setDateSortStart(null)
			setDateSortEnd(null)
		}
	}

	useEffect(() => {
		const today = dayjs()
		const startDate = today.subtract(21, 'day')
		const endDate = today

		onChangeDate([startDate, endDate])
	}, [])

	const formatDateForApi = (date: Dayjs | null) =>
		date ? date.format('DD.MM.YYYY') : null

	const [selectedProducts, setSelectedProducts] = useState<Array<string>>([])
	const [selectedAvailableType, setSelectedAvailableType] = useState<
		null | boolean
	>(true)
	const [selectedParticipatingType, setSelectedParticipatingType] = useState<
		null | boolean
	>(null)
	const [selectedActionType, setSelectedActionType] = useState<null | string[]>(
		null
	)

	const {
		products,
		search: searchProduct,
		setSearch: setSearchProduct,
		setNotEmptyMode,
	} = useProductList()

	const {
		data: sData,
		error,
		isLoading,
		refetch,
		...options
	} = useQuery(
		[
			`${dataType}_DATA`.toUpperCase(),
			dataType,
			selectedSeller,
			debouncedSearchText,
			selectedAvailableType,
			selectedProducts,
			dateSortStart,
			dateSortEnd,
			currentPageSize,
			currentPage,
		],
		() =>
			backend.unitEconomics.getData(
				dataType,
				selectedSeller,
				debouncedSearchText,
				selectedAvailableType,
				selectedProducts,
				formatDateForApi(dateSortStart),
				formatDateForApi(dateSortEnd),
				currentPageSize,
				currentPage
			),
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			enabled:
				!!selectedSeller && !!dateSortStart && !!dateSortEnd && !!dataType,

			onError: err => {
				console.error(`Failed to fetch ${dataType} data: ${err}`)
			},
			onSuccess: data => {
				const newSettingsData = data?.result ? data.result : data
				setUnitEconomicsData(newSettingsData)
			},
		}
	)

	const getProductsFilterItems = () => {
		if (!products) {
			return []
		}

		let allItems: any[] = []
		let selectedItems: any[] = []

		products?.forEach((item: any) => {
			const isDuplicate = selectedProducts.some(
				(productItem: any) =>
					productItem?.id?.toString() === item?.id?.toString()
			)

			if (!isDuplicate) {
				allItems.push({
					label: (
						<div
							style={{ display: 'flex', gap: '10px' }}
							onClick={e => {
								e.stopPropagation()
							}}
						>
							<Checkbox
								style={{ width: '100%' }}
								value={item.title}
								checked={selectedProducts.some(
									(productItems: any) =>
										productItems?.id?.toString() === item?.id?.toString()
								)}
								onChange={e => {
									let newSelectedProducts = [...selectedProducts]
									if (e.target.checked) {
										newSelectedProducts.push(item)
									} else {
										newSelectedProducts = newSelectedProducts.filter(
											(el: any) => el?.id?.toString() !== item?.id?.toString()
										)
									}
									setSelectedProducts(newSelectedProducts)
								}}
							>
								{item.title}
							</Checkbox>
						</div>
					),
					key: `product-${item.id}`,
				})
			}
		})

		selectedProducts?.forEach((item: any) => {
			const isDuplicate = selectedProducts.some(
				(productItem: any) =>
					productItem?.id?.toString() === item?.id?.toString()
			)

			if (isDuplicate) {
				selectedItems.push({
					label: (
						<div
							style={{ display: 'flex', gap: '10px' }}
							onClick={e => {
								e.stopPropagation()
							}}
						>
							<Checkbox
								style={{ width: '100%' }}
								value={item.title}
								checked={selectedProducts.some(
									(productItems: any) =>
										productItems?.id?.toString() === item?.id?.toString()
								)}
								onChange={e => {
									let newSelectedProducts = [...selectedProducts]
									if (e.target.checked) {
										newSelectedProducts.push(item)
									} else {
										newSelectedProducts = newSelectedProducts.filter(
											(el: any) => el?.id?.toString() !== item?.id?.toString()
										)
									}
									setSelectedProducts(newSelectedProducts)
								}}
							>
								{item.title}
							</Checkbox>
						</div>
					),
					key: `product-${item.id}`,
				})
			}
		})

		const items: MenuProps['items'] = [
			{
				label: (
					<Input
						value={searchProduct}
						className={'analytic-dropdown-input'}
						onClick={e => e?.stopPropagation()}
						onChange={e => setSearchProduct(e?.target?.value)}
						prefix={<Icon component={searchIcon} />}
						placeholder={'Поиск...'}
					/>
				),
				key: 'product-search',
			},
			...selectedItems,
			...allItems,
		]

		return items
	}

	const getSellersFilterItems = () => {
		if (!sellers) {
			return []
		}

		let allItems: any[] = []
		let selectedItems: any[] = []

		sellers?.forEach((item: any) => {
			const isDuplicate = selectedSeller.some(
				(sellerItem: any) => sellerItem?.id.toString() === item?.id.toString()
			)

			if (!isDuplicate) {
				allItems.push({
					label: (
						<div
							style={{ display: 'flex', gap: '10px' }}
							onClick={e => {
								e.stopPropagation()
							}}
						>
							<Checkbox
								style={{ width: '100%' }}
								value={item?.company_name}
								checked={selectedSeller.some(
									(sellerItem: any) =>
										sellerItem?.id.toString() === item?.id.toString()
								)}
								onChange={e => {
									let newSelectedSellers = [...selectedSeller]
									if (e.target.checked) {
										newSelectedSellers.push(item)
									} else {
										newSelectedSellers = newSelectedSellers.filter(
											(el: any) => el?.id.toString() !== item?.id.toString()
										)
									}
									setSelectedSeller(newSelectedSellers)
								}}
							>
								{item?.company_name}
							</Checkbox>
						</div>
					),
					key: `seller-${item.id.toString()}`,
				})
			}
		})

		selectedSeller?.forEach((item: any) => {
			const isDuplicate = selectedSeller.some(
				(sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString()
			)

			if (isDuplicate) {
				selectedItems.push({
					label: (
						<div
							style={{ display: 'flex', gap: '10px' }}
							onClick={e => {
								e.stopPropagation()
							}}
						>
							<Checkbox
								style={{ width: '100%' }}
								value={item?.company_name}
								checked={selectedSeller.some(
									(sellerItem: any) =>
										sellerItem?.id.toString() === item?.id.toString()
								)}
								onChange={e => {
									let newSelectedSellers = [...selectedSeller]
									if (e.target.checked) {
										newSelectedSellers.push(item)
									} else {
										newSelectedSellers = newSelectedSellers.filter(
											(el: any) => el?.id.toString() !== item?.id.toString()
										)
									}
									setSelectedSeller(newSelectedSellers)
								}}
							>
								{item?.company_name}
							</Checkbox>
						</div>
					),
					key: `seller-${item.id}`,
				})
			}
		})

		const items: MenuProps['items'] = [
			{
				label: (
					<Input
						value={searchSellers}
						className={'analytic-dropdown-input'}
						onClick={e => e?.stopPropagation()}
						onChange={e => setSearchSellers(e?.target?.value)}
						prefix={<Icon component={searchIcon} />}
						placeholder={'Поиск...'}
					/>
				),
				key: 'sizes-search',
			},
			...selectedItems,
			...allItems,
		]

		return items
	}

	// const [loadingCheckboxId, setLoadingCheckboxId] = useState<
	// 	string | number | null
	// >(null)

	const editSummary = useMutation(
		async ({
			id,
			appear_date,
			buy_count_cycle,
			ads_spend_balance,
			ads_spend_bill,
			ads_spend_bonus,
			marketing_spends,
			product_buys_cycle,
			fulfillment_price,
			support_cost,
			tvt_cost,
			tvt_payment_date,
		}: {
			id: number
			appear_date?: string
			buy_count_cycle?: number
			ads_spend_balance?: number
			ads_spend_bill?: number
			ads_spend_bonus?: number
			marketing_spends?: number
			product_buys_cycle?: number
			fulfillment_price?: number
			support_cost?: number
			tvt_cost?: number
			tvt_payment_date?: string
		}) =>
			await backend.unitEconomics.editSummary(
				id,
				appear_date,
				buy_count_cycle,
				ads_spend_balance,
				ads_spend_bill,
				ads_spend_bonus,
				marketing_spends,
				product_buys_cycle,
				fulfillment_price,
				support_cost,
				tvt_cost,
				tvt_payment_date
			),
		{
			onSuccess: () => {
				message.success('Изменение сохранено.')
				queryClient.invalidateQueries(`${dataType}_DATA`.toUpperCase())
			},
			// onSettled: () => {
			// 	setLoadingCheckboxId(null)
			// },
		}
	)

	const editBuyout = useMutation(
		async ({
			id,
			article,
			start_date,
			end_date,
			self_buyout_photo,
			self_buyout_text_count,
			self_buyout_cost,
			send_to_clients_for_buyout,
			payment_date,
		}: {
			id: number
			article?: string
			start_date?: string
			end_date?: string
			self_buyout_photo?: number
			self_buyout_text_count?: number
			self_buyout_cost?: number
			send_to_clients_for_buyout?: number
			payment_date?: string
		}) =>
			await backend.unitEconomics.editBuyout(
				id,
				article,
				start_date,
				end_date,
				self_buyout_photo,
				self_buyout_text_count,
				self_buyout_cost,
				send_to_clients_for_buyout,
				payment_date
			),
		{
			onSuccess: () => {
				message.success('Изменение сохранено.')
				queryClient.invalidateQueries(`${dataType}_DATA`.toUpperCase())
			},
			// onSettled: () => {
			// 	setLoadingCheckboxId(null)
			// },
		}
	)

	const exportPrices = useMutation(
		async ({ prices }: { prices: any }) =>
			backend.unitEconomics.setPrices(prices),
		{
			onSuccess: () => {
				// queryClient.invalidateQueries('ACTIONS_NOMENCLATURES_DATA')
				message.success('Изменение сохранено.')
				setUnitEconomicsData(null)
			},
			onError: () => {
				message.error('Не удалось загрузить цены')
			},
		}
	)

	const generateFilename = (): string => {
		const currentDate = dayjs().format('YYYY-MM-DD')

		// const action = pricesData.find(
		// 	(action: { id: string | number; name: string }) =>
		// 		String(action.id) === String(actionId)
		// )

		// if (!action) {
		// 	console.error(`Action with id ${actionId} not found in actionsData`)
		// 	return `action_report_${currentDate}.xlsx`
		// }

		return `prices_${currentDate}.xlsx`
	}
	const [downloadLoading, setDownloadLoading] = useState(false)
	const handleDownloadAllPrices = useMutation(
		async () => {
			setDownloadLoading(true)
			return backend.unitEconomics.downloadAllPrices()
		},
		{
			onError: err => {
				console.error(`Failed to download data: ${err}`)
				message.error('Не удалось скачать Excel файл. Попробуйте еще раз.')
			},
			onSuccess: (data: Blob) => {
				try {
					const url = window.URL.createObjectURL(new Blob([data]))
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', generateFilename())
					link.setAttribute('target', '_blank')
					document.body.appendChild(link)
					link.click()
					link.remove()
					message.success('Файл успешно загружен!')
				} catch (error) {
					console.error(`Error while saving the file: ${error}`)
					message.error('Ошибка при сохранении файла.')
				}
			},
			onSettled: () => {
				setDownloadLoading(false)
			},
		}
	)

	const handleAddBuyout = useMutation(
		async ({
			article,
			self_buyout_photo,
			self_buyout_text_count,
			send_to_clients_for_buyout,
			start_date,
			end_date,
			payment_date,
		}: {
			article: string
			self_buyout_photo: number
			self_buyout_text_count: number
			send_to_clients_for_buyout: number
			start_date: string
			end_date: string
			payment_date: string
		}) =>
			await backend.unitEconomics.setBuyout(
				article,
				self_buyout_photo,
				self_buyout_text_count,
				send_to_clients_for_buyout,
				start_date,
				end_date,
				payment_date
			),
		{
			onSuccess: () => {
				message.success('Выкуп успешно загружен.')
				queryClient.invalidateQueries('BUYOUTS_DATA')
			},
			onError: (error: any) => {
				console.error(`Error adding buyout:`, error)
				message.error(`Failed to add buyout. Please try again.`)
			},
		}
	)

	const handleDeleteBuyout = useMutation(
		async ({ id }: { id: number }) =>
			await backend.unitEconomics.deleteBuyout(id),
		{
			onSuccess: () => {
				message.success('Выкуп успешно удален.')
				queryClient.invalidateQueries('BUYOUTS_DATA')
			},
			onError: (error: any) => {
				console.error(`Error deleting planned price:`, error)
				message.error(`Failed to delete planned price. Please try again.`)
			},
		}
	)

	return {
		unitEconomicsData,
		nomenclatures: nomenclaturesData,
		selectedSeller,
		setSelectedSeller,
		selectedProducts,
		setSelectedProducts,
		dataType,
		setDataType,
		sellers,
		setSearchSellers,
		searchSellers,
		isLoading,
		error,

		setPromotions,
		promotions,
		currentPage,
		setCurrentPage,
		currentPageSize,
		setCurrentPageSize,

		dateSortStart,
		dateSortEnd,
		onChangeDate,

		getProductsFilterItems,
		getSellersFilterItems,

		searchText,
		setSearchText,

		selectedAvailableType,
		setSelectedAvailableType,
		selectedParticipatingType,
		setSelectedParticipatingType,
		selectedActionType,
		setSelectedActionType,
		// setAvailable: setInAction.mutate,
		// setLoadingCheckboxId,
		// loadingCheckboxId,

		selectedChangeType,
		setSelectedChangeType,
		selectedSeenType,
		setSelectedSeenType,
		exportPrices: exportPrices.mutate,
		editSummary: editSummary.mutate,
		editBuyout: editBuyout.mutate,
		handleDownloadAllPrices: handleDownloadAllPrices.mutate,
		downloadLoading,
		handleAddBuyout: handleAddBuyout.mutate,
		handleDeleteBuyout: handleDeleteBuyout.mutate,

		...options,
	}
}
