// Requests
import {api, apiKizFullInfo} from "../index";

export async function getCostPriceData(
    page?: string,
    pageSize?: string,
    sortParam?: string,
    sortType?: string,
    sellers?: string[],
    periodStartDate?: string,
    periodEndDate?: string,
    search?: string
) {
    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }
    if (search) {
        params.append("search", search);
    }
    if (sortParam && sortType) {
        params.append("filter_by", sortParam);
        params.append("order_by", sortType);
    }
    if (sellers && sellers.length > 0) {
        params.append("seller_ids", sellers?.map((item: any) => item?.id).join(","));
    }
    if (periodStartDate && periodEndDate){
        params.append("period_start_date", periodStartDate);
        params.append("period_end_date", periodEndDate);

    }
    const response = await api.get<any>(`/self-costs`, {
        params,
    });
    return response.data.data;
}


export async function getCostPriceById(
    id: string,
) {
    if (id) {
        const response = await api.get(`/self-costs/${id}`);
        return response?.data?.data;
    }
}

export async function deleteCostPrice(id: any) {
    if (id) {
        const response = await api.delete(`/self-costs/${id}`);
        return response.data;
    }
}