import React from 'react'
import { Table } from 'antd'
import TitleWithTooltip from 'features/actions/components/TitleWithTooltip'
import TableHeader from './TableHeader'
import dayjs from 'dayjs'
import ChangeValue from './ChanageValue'

interface DataType {
	key: string
	name?: string
	// avg?: number | string | null
	// dates?: any[]
}

interface DynamicTableProps {
	data: any
	columnsData: DataType[]
	addDateComment: (data: {
		product_id: string | number
		comment: string
		date?: string
		weekId?: number
		avgWeekId?: number
	}) => void
}

function getWeekNumber(d: Date): number {
	d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
	d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
	const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
	const weekNo = Math.ceil(((+d - +yearStart) / 86400000 + 1) / 7)
	return weekNo
}

const DynamicTable: React.FC<DynamicTableProps> = ({
	data,
	columnsData,
	addDateComment,
}) => {
	console.log('data date:', data)
	const columns = [
		...(data?.data && Object.keys(data.data).length > 0
			? Object.keys(data.data)
					.sort((a, b) => {
						const firstDateA = new Date(data.data[a].days[0]?.date).getTime()
						const firstDateB = new Date(data.data[b].days[0]?.date).getTime()
						return firstDateA - firstDateB
					})
					.flatMap(weekKey => [
						{
							title: (
								<TableHeader
									title={`W${weekKey}`}
									style={{
										borderLeft: '5px solid #434343',
									}}
								/>
							),
							dataIndex: 'week',
							key: `week_${weekKey}`,
							width: 116,
							align: 'center' as const,

							render(value: number, record: any) {
								return {
									props: {
										style: {
											background: record.color,
											height: 25,
											maxHeight: 25,
											lineHeight: '25px',
											padding: 0,
											minWidth: 120,
											borderLeft: '5px solid #434343',
										},
									},
									children:
										record.key === 'comment' ? (
											<ChangeValue
												// fontSize={12}
												rows={1}
												maxWidth={120}
												maxHeight={25}
												value={
													data.data[weekKey]?.week[record.key].comment ||
													'Комментарий'
												}
												onChange={(value: string | number) =>
													addDateComment({
														product_id: data.productId,
														comment: value.toString(),
														weekId: Number(weekKey),
													})
												}
											/>
										) : (
											<TitleWithTooltip
												title={`${
													data.data[weekKey]?.week[record.key] != null
														? data.data[weekKey].week[record.key]
																.toFixed(record.digitsAfterComa)
																.toString()
																.replace('.', ',')
														: ''
												}
											${data.data[weekKey]?.week[record.key] ? record.symbol : ''}`}
												rows={1}
												color={record.textColor}
												fontWeight={700}
											/>
										),
								}
							},
						},
						{
							title: <TableHeader title='ср. знач.' />,
							dataIndex: 'avgDays',
							key: `avgDays`,
							width: 75,
							align: 'center' as const,

							render(value: number, record: any) {
								console.log(data.data)
								return {
									props: {
										style: {
											background: record.color,
											height: 25,
											maxHeight: 25,
											lineHeight: '25px',
											padding: 0,
											minWidth: 80,
											maxWidth: 80,
										},
									},
									children:
										record.key === 'comment' ? (
											<ChangeValue
												// fontSize={12}
												maxWidth={80}
												maxHeight={25}
												rows={1}
												value={
													data.data[weekKey]?.avgDays?.[record.key].comment ||
													'Комментарий'
												}
												onChange={(value: string | number) =>
													addDateComment({
														product_id: data.productId,
														comment: value.toString(),
														avgWeekId: Number(weekKey),
													})
												}
											/>
										) : (
											<TitleWithTooltip
												title={`${
													data.data[weekKey]?.avgDays?.[record.key] != null
														? data.data[weekKey]?.avgDays[record.key]
																.toFixed(record.digitsAfterComa)
																.toString()
																.replace('.', ',')
														: ''
												}
											${
												record.symbol.length > 0
													? data.data[weekKey]?.avgDays?.[record.key]
														? record.symbol
														: ''
													: data.data[weekKey]?.avgDays?.[record.key]
													? record.avgSymbol
													: ''
											}`}
												rows={1}
												color={record.textColor}
												fontSize={
													record?.avgFontSize
														? record.avgFontsize
														: record.fontSize
												}
												fontWeight={
													record.avgFontWeight ? record.avgFontWeight : 500
												}
											/>
										),
								}
							},
						},
						...data.data[weekKey].days.map((date: any) => ({
							title: <TableHeader title={dayjs(date.date).format('DD.MM')} />,
							dataIndex: 'dates',
							key: `dates_${weekKey}_${date.date}`,
							width: 128,
							align: 'center' as const,

							render(value: number, record: any) {
								console.log(record.key)
								return {
									props: {
										style: {
											background: record.color,
											height: 25,
											maxHeight: 25,
											lineHeight: '25px',
											padding: 0,
											minWidth: 100,
										},
									},
									children:
										record.key === 'comment' ? (
											<ChangeValue
												maxWidth={100}
												maxHeight={25}
												rows={1}
												// fontSize={12}
												value={date[record.key].comment || 'Комментарий'}
												onChange={(value: string | number) =>
													addDateComment({
														product_id: data.productId,
														comment: value.toString(),
														date: date.date,
													})
												}
											/>
										) : (
											<TitleWithTooltip
												title={
													date && date[record.key] != null
														? `${date[record.key]
																.toFixed(record.digitsAfterComa)
																.toString()
																.replace('.', ',')}${
																record.symbol ? record.symbol : ''
														  }`
														: ''
												}
												rows={1}
												color={record.textColor}
												fontSize={record.fontSize}
												fontWeight={record.fontWeight}
											/>
										),
								}
							},
						})),
					])
			: [
					{
						title: <TableHeader title='Нет данных' />,
						dataIndex: 'empty',
						key: 'empty',
						width: 116,
						align: 'center' as const,

						render(value: number, record: any) {
							return {
								props: {
									style: {
										background: record.color,
										height: 25,
										maxHeight: 25,
										lineHeight: '25px',
										padding: 0,
									},
								},
								children: (
									<div
										style={{
											color: record.textColor,
											padding: '0 8px',
											width: '100%',
											fontWeight: 'normal',
										}}
									>
										{''}
									</div>
								),
							}
						},
					},
			  ]),
	]

	return (
		<div className='custom-table'>
			<Table
				locale={{
					emptyText: 'Нет данных',
					filterTitle: 'Фильтр',
					filterReset: 'Очистить',
					filterEmptyText: 'Нет фильтров',
				}}
				dataSource={columnsData || []}
				columns={columns}
				rowClassName={() => 'custom-row'}
				// rowKey={(record: any) => record.id}
				scroll={{
					x: true,
					y: undefined,
				}}
				size='small'
				tableLayout='fixed'
				pagination={false}
				style={{ width: '100%', overflowX: 'auto' }}
			/>
		</div>
	)
}

export default DynamicTable
