import { Button, Col, Form, Input, Modal, Row, DatePicker, message } from 'antd'
import ModalHeader from 'features/shared/ModalHeader'
import React from 'react'

export default function UploadBuyout({
	setData,
	onClose,
	title = 'Добавить данные',
	isOpen,
}: {
	setData?: any
	onClose: () => void
	title: string
	isOpen: boolean
}) {
	const [form] = Form.useForm()

	const onFinish = async (values: any) => {
		const {
			article,
			self_buyout_photo,
			self_buyout_text_count,
			send_to_clients_for_buyout,
			start_date,
			end_date,
			payment_date,
		} = values

		// if (!article || !start_date || !end_date) {
		// 	return message.error('Заполните все обязательные поля')
		// }

		const data = {
			article: article,
			self_buyout_photo: self_buyout_photo,
			self_buyout_text_count: self_buyout_text_count,
			send_to_clients_for_buyout: send_to_clients_for_buyout,
			start_date: start_date.format('DD.MM.YYYY'),
			end_date: end_date.format('DD.MM.YYYY'),
			payment_date: payment_date
				? payment_date.format('DD.MM.YYYY')
				: undefined,
		}

		setData && setData(data)
		onClose()
		form.resetFields()
	}

	return (
		<Modal
			open={isOpen}
			closable={false}
			footer={null}
			width={'100%'}
			style={{
				maxWidth: 600,
				minWidth: 360,
				padding: '0 10px',
			}}
		>
			<div className='modal-wrapper' style={{ padding: '30px', width: '90%' }}>
				<ModalHeader
					title={title}
					onClose={() => {
						onClose()
						form.resetFields()
					}}
				/>
				<Row
					className='container'
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
					}}
				>
					<Form
						form={form}
						layout='vertical'
						onFinish={onFinish}
						style={{
							width: '100%',
						}}
					>
						<Form.Item
							label='Артикул'
							name='article'
							rules={[{ required: true, message: 'Введите артикул' }]}
						>
							<Input placeholder='Введите артикул' />
						</Form.Item>

						<Form.Item
							label='Самовыкуп с фотоотзывом (шт)'
							name='self_buyout_photo'
							rules={[{ required: true, message: 'Введите количество' }]}
						>
							<Input placeholder='Введите количество' type='number' />
						</Form.Item>

						<Form.Item
							label='Самовыкуп с текстовым отзывом (шт)'
							name='self_buyout_text_count'
							rules={[{ required: true, message: 'Введите количество' }]}
						>
							<Input placeholder='Введите количество' type='number' />
						</Form.Item>

						<Form.Item
							label='Перечислено клиентом на самовыкуп (руб)'
							name='send_to_clients_for_buyout'
							rules={[{ required: true, message: 'Введите сумму' }]}
						>
							<Input placeholder='Введите сумму' type='number' />
						</Form.Item>

						<Form.Item
							label='Начало самовыкупа'
							name='start_date'
							rules={[{ required: true, message: 'Выберите дату начала' }]}
						>
							<DatePicker
								style={{ width: '100%' }}
								placeholder='Выберите дату'
							/>
						</Form.Item>

						<Form.Item
							label='Конец самовыкупа'
							name='end_date'
							rules={[{ required: true, message: 'Выберите дату окончания' }]}
						>
							<DatePicker
								style={{ width: '100%' }}
								placeholder='Выберите дату'
							/>
						</Form.Item>

						<Form.Item
							label='Дата оплаты'
							name='payment_date'
							rules={[{ required: true, message: 'Выберите дату оплаты' }]}
						>
							<DatePicker
								style={{ width: '100%' }}
								placeholder='Выберите дату'
							/>
						</Form.Item>

						<Row gutter={16} style={{ justifyContent: 'flex-end' }}>
							<Col span={12}>
								<Button
									type='primary'
									style={{ width: '100%' }}
									htmlType='submit'
								>
									Добавить
								</Button>
							</Col>
						</Row>
					</Form>
				</Row>
			</div>
		</Modal>
	)
}
