import { Col, Row } from 'antd'
import React from 'react'
import DetailsTable from 'features/unitEconomics/DetailsTable'

export default function DetailsPage() {
	return (
		<Row style={{ padding: '10px 0' }}>
			<Col className={'container'} span={24}>
				<DetailsTable />
			</Col>
		</Row>
	)
}
