import React, { useState } from 'react'
import { Col, Row } from 'antd'
import { useNavigate, Link, useLocation, Outlet } from 'react-router-dom'

const UnitEconomicsPage = () => {
	const location = useLocation()
	const navigate = useNavigate()
	return (
		<Row>
			<Col style={{ padding: '10px 20px' }} span={24}>
				<div className={'storage-tabs'}>
					<div
						className={`${
							location.pathname.includes('/summary') ? 'tab-active' : ''
						}`}
						onClick={() => navigate('/unit-economics/summary')}
					>
						Сводный отчет
					</div>
					<div
						className={`${
							location.pathname.includes('/details') ? 'tab-active' : ''
						}`}
						onClick={() => navigate('/unit-economics/details')}
					>
						Детальный отчет
					</div>
					<div
						className={`${
							location.pathname.includes('/buyouts') ? 'tab-active' : ''
						}`}
						onClick={() => navigate('/unit-economics/buyouts')}
					>
						Выкупы
					</div>
				</div>
				<Row>
					<Col span={24}>
						<Outlet />
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default UnitEconomicsPage
