import React, { useEffect, useMemo, useState } from 'react'
import {
	Button,
	Col,
	DatePicker,
	Dropdown,
	Input,
	message,
	Modal,
	Pagination,
	Row,
	Select,
	Spin,
	Table,
} from 'antd'
import Icon, {
	DeleteOutlined,
	DownloadOutlined,
	LoadingOutlined,
} from '@ant-design/icons'
import selectIcon, {
	ReactComponent as SelectIconComponent,
} from '../../assets/images/select-icon.svg'
import { PickSeller } from './components/PickSeller'
import useUnitEconomicsData from './hooks/unit-economics/useUnitEconomicsData'
import CopyToClipboard from 'react-copy-to-clipboard'
import { PickPageSize } from './components/PickPageSize'
import { PickIsNew } from './components/PickIsNew'
import { useTableColumns } from './hooks/unit-economics/useTableColumns'
import { PickVisibleColumns } from './components/PickVisibleColumns'
import TitleWithTooltip from 'features/actions/components/TitleWithTooltip'
import NoImage from 'assets/icon/no-image'
import BeforeAfter from './BeforeAfter'
import { DownloadExcel } from './components/DownloadExcel'
import { UploadExcel } from './components/UploadExcel'
import { PickIsAvailable } from './components/PickIsAvailable'
import dayjs from 'dayjs'
import HandleUploadBuyout from './components/HandleUploadBuyout'
import EditValue from './EditValue'
import ConfirmModal from 'components/ConfirmModal'
import { ReactComponent as rangePickerArrow } from '../../assets/images/rangepicker-arrow.svg'

const { RangePicker } = DatePicker

const BuyoutsTable = () => {
	const {
		unitEconomicsData,
		selectedSeller,
		setSelectedSeller,
		sellers,
		setDataType,
		isLoading,
		onChangeDate,
		dateSortStart,
		dateSortEnd,
		getSellersFilterItems,
		selectedActionType,
		setSelectedActionType,
		searchText,
		setSearchText,
		selectedAvailableType,
		setSelectedAvailableType,
		currentPageSize,
		setCurrentPageSize,
		currentPage,
		setCurrentPage,
		getProductsFilterItems,
		editBuyout,
		handleDownloadAllPrices,
		downloadLoading,
		handleAddBuyout,
		handleDeleteBuyout,
	} = useUnitEconomicsData()

	// const [data, setData] = useState(unitEconomicsData)
	const [data, setData] = useState({ data: [], total: 0 })
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<null | number>(
		null
	)

	console.log(data)
	useEffect(() => {
		setDataType('buyouts')
		if (unitEconomicsData) {
			setData(unitEconomicsData)
		}
	}, [unitEconomicsData])

	const columns = useMemo(
		() => [
			{
				title: 'Товар',
				dataIndex: 'photoUrl',
				key: 'photoUrl',
				width: '3%',
				align: 'center' as const,
				fixed: 'left',
				render: (photo: string, record: any) => {
					const { wbArticle, linkOnSite } = record || {}
					return (
						<div
							className='analytics-col-title__wrap'
							style={{ padding: '5px 6px 5px 6px' }}
						>
							<span
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									gap: 3,
								}}
							>
								<CopyToClipboard
									text={`${wbArticle || ''}`}
									onCopy={() => message.success('Текст скопирован')}
								>
									<div
										className='analytics-col-title'
										style={{ cursor: 'pointer' }}
									>
										{wbArticle || 'Нет данных'}
									</div>
								</CopyToClipboard>
								<EditValue
									value={wbArticle || ''}
									onValueChange={value =>
										editBuyout({
											id: record.id,
											article: value.toString(),
										})
									}
									type={'invisible'}
								/>
							</span>
							<a href={linkOnSite} target='_blank' rel='noopener noreferrer'>
								{photo ? (
									<img
										src={photo}
										style={{
											width: '90px',
											minHeight: 120,
											maxHeight: 120,
										}}
										alt='Товар'
										onError={e => {
											e.currentTarget.onerror = null
											e.currentTarget.src = ''
											e.currentTarget.style.display = 'none'
											const noImage = e.currentTarget
												.nextElementSibling as HTMLElement
											if (noImage) noImage.style.display = 'block'
										}}
									/>
								) : null}
								<NoImage
									style={{
										width: '90px',
										minHeight: 120,
										maxHeight: 120,
										display: photo ? 'none' : 'block',
									}}
								/>
							</a>
							{/* {category && <div className='analytics-col-title'>{category}</div>} */}
						</div>
					)
				},
			},
			{
				title: <TitleWithTooltip title='Дата начала выкупа' />,
				dataIndex: 'startDate',
				key: 'startDate',
				width: '5%',
				align: 'center' as const,
				render(date: any, record: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								<EditValue
									value={date}
									onValueChange={value =>
										editBuyout({
											id: record.id,
											start_date: value.toString(),
										})
									}
									type={'date'}
								/>
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Дата окончания выкупа' />,
				dataIndex: 'endDate',
				key: 'endDate',
				width: '5%',
				align: 'center' as const,
				render(date: any, record: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								<EditValue
									value={date}
									onValueChange={value =>
										editBuyout({
											id: record.id,
											end_date: value.toString(),
										})
									}
									type={'date'}
								/>
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Юр. Лицо' />,
				dataIndex: 'companyName',
				key: 'companyName',
				width: 150,
				align: 'center' as const,
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Самовыкуп для фотоотзывов, шт.' />,
				dataIndex: 'selfBuyoutPhoto',
				key: 'selfBuyoutPhoto',
				width: 50,
				align: 'center' as const,
				render(text: any, record: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								<EditValue
									value={text}
									onValueChange={value =>
										editBuyout({
											id: record.id,
											self_buyout_photo: Number(value),
										})
									}
									type={'count'}
								/>
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Самовыкуп для текстового отзыва, шт.' />,
				dataIndex: 'selfBuyoutText',
				key: 'selfBuyoutText',
				width: 50,
				align: 'center' as const,
				render(text: any, record: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								<EditValue
									value={text}
									onValueChange={value =>
										editBuyout({
											id: record.id,
											self_buyout_text_count: Number(value),
										})
									}
									type={'count'}
								/>
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Услуга самовыкупа, руб.' />,
				dataIndex: 'selfBuyoutCost',
				key: 'selfBuyoutCost',
				width: 50,
				align: 'center' as const,
				render(text: any, record: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : 0}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Удержания WB с самовыкупов, руб.' />,
				dataIndex: 'wbBuyoutCommission',
				key: 'wbBuyoutCommission',
				width: 50,
				align: 'center' as const,
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Расходы на самовыкупы, руб.' />,
				dataIndex: 'totalSelfBuyoutExpenses',
				key: 'totalSelfBuyoutExpenses',
				width: 50,
				align: 'center' as const,
				render(text: any, record: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text}
							</div>
						),
					}
				},
			},
			{
				title: (
					<TitleWithTooltip title='Перечислено клиентом на самовыкуп, руб.' />
				),
				dataIndex: 'sendToClientsForBuyout',
				key: 'sendToClientsForBuyout',
				width: 50,
				align: 'center' as const,
				render(text: any, record: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								<EditValue
									value={text}
									onValueChange={value =>
										editBuyout({
											id: record.id,
											send_to_clients_for_buyout: Number(value),
										})
									}
									type={'number'}
								/>
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Дата перечисления денежных средств' />,
				dataIndex: 'paymentDate',
				key: 'paymentDate',
				width: 50,
				align: 'center' as const,
				render(date: any, record: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								<EditValue
									value={date}
									onValueChange={value =>
										editBuyout({
											id: record.id,
											payment_date: value.toString(),
										})
									}
									type={'date'}
								/>
							</div>
						),
					}
				},
			},
			{
				title: (
					<TitleWithTooltip title='Перечислено за выкуп от ВБ (за вычетом расходов)' />
				),
				dataIndex: 'fromWbIgnoreSpends',
				key: 'fromWbIgnoreSpends',
				width: 50,
				align: 'center' as const,
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Действие' />,
				dataIndex: 'inAction',
				key: 'inAction',
				width: '5%',
				align: 'center' as const,
				render: (value: boolean, record: any) => (
					<>
						<Button
							style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
							type='primary'
							onClick={() => setConfirmDeleteOpen(record.id)}
							icon={<DeleteOutlined />}
						></Button>
					</>
				),
			},
		],
		[data]
	)

	const { visibleColumns, toggleColumn } = useTableColumns(columns)

	const tableColumns = visibleColumns.filter((col: any) => col.visible)

	return (
		<div style={{ backgroundColor: '#f8f8f8' }}>
			<Row gutter={6} align={'middle'} justify={'end'} style={{ gap: 5 }}>
				<Col style={{ flexGrow: '1' }}>
					<Input
						className='table-header-input'
						placeholder={'Поиск'}
						value={searchText}
						onChange={e => setSearchText(e.target.value)}
						type={'number'}
					/>
				</Col>
				<Col style={{ width: '120px' }}>
					<PickSeller
						getSellersFilterItems={getSellersFilterItems}
						selectedSeller={selectedSeller}
					/>
				</Col>
				<Col style={{ width: '160px' }}>
					<PickIsAvailable
						selectedAvailableType={selectedAvailableType}
						setSelectedAvailableType={setSelectedAvailableType}
					/>
				</Col>
				<Col style={{ width: '98px' }}>
					<Dropdown
						trigger={['click']}
						placement={'bottomRight'}
						menu={{ items: getProductsFilterItems() }}
						overlayClassName={'dropdown-border'}
					>
						<div className={'analytics-header-dropdown '}>
							<div>Предмет</div>
							<Icon
								component={SelectIconComponent}
								style={{ marginTop: '2px', fontSize: '10px' }}
							/>
						</div>
					</Dropdown>
				</Col>
				<Col>
					<RangePicker
						onChange={onChangeDate}
						placeholder={['Начало', 'Конец']}
						separator={<Icon component={rangePickerArrow} />}
						format={'DD.MM.YYYY'}
						value={[dateSortStart, dateSortEnd]}
					/>
				</Col>
				<Col>
					<PickVisibleColumns
						columns={visibleColumns}
						onToggle={toggleColumn}
					/>
				</Col>
				<Col style={{ width: '190px' }}>
					<PickPageSize
						currentPageSize={currentPageSize}
						setCurrentPage={setCurrentPage}
						setCurrentPageSize={setCurrentPageSize}
					/>
				</Col>
				<Col>
					<HandleUploadBuyout
						title={'Загрузить поштучно'}
						setData={handleAddBuyout}
					/>
				</Col>
				<Col>
					<UploadExcel
						title={'Загрузить файл'}
						type={'import-planned-prices'}
					/>
				</Col>
				<Col>
					<DownloadExcel
						handleDownload={handleDownloadAllPrices}
						isLoading={downloadLoading}
						title={'Скачать шаблон'}
					/>
				</Col>
			</Row>

			<Row className={'container'} style={{ marginTop: '10px' }}>
				{isLoading ? (
					<div
						style={{
							margin: '0 auto',
							padding: '150px',
							textAlign: 'center',
						}}
					>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
					</div>
				) : data?.data.length === 0 ? (
					<div style={{ margin: '0 auto', padding: 150 }}>Нет данных</div>
				) : (
					<Col span={24}>
						<Table
							columns={tableColumns}
							locale={{
								emptyText: 'Нет данных',
								filterTitle: 'Фильтр',
								filterReset: 'Очистить',
								filterEmptyText: 'Нет фильтров',
							}}
							dataSource={data ? data.data : []}
							pagination={false}
							rowKey={(record: any) => record.id}
							scroll={{ x: 'max-content', y: '65vh' }}
						/>
						<Col span={24}>
							<Pagination
								onChange={page => setCurrentPage(page)}
								current={currentPage}
								defaultCurrent={1}
								pageSize={parseInt(currentPageSize)}
								total={data?.total}
								style={{
									textAlign: 'center',
									padding: '16px 0',
								}}
								showSizeChanger={false}
							/>
						</Col>
					</Col>
				)}
			</Row>
			<Modal
				open={!!confirmDeleteOpen}
				footer={null}
				onCancel={() => setConfirmDeleteOpen(null)}
			>
				<ConfirmModal
					onClose={() => setConfirmDeleteOpen(null)}
					onConfirm={() => {
						if (confirmDeleteOpen !== null) {
							handleDeleteBuyout({ id: confirmDeleteOpen })
						}
						setConfirmDeleteOpen(null)
					}}
					text='Вы уверены, что хотите удалить данный выкуп?'
				/>
			</Modal>
		</div>
	)
}

export default BuyoutsTable
