import { Select, Dropdown } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import useActionsData from '../hooks/unit-economics/useUnitEconomicsData'
import Icon from '@ant-design/icons'
import { ReactComponent as SelectIconComponent } from 'assets/images/select-icon.svg'

const { Option } = Select

interface ISellerPicker {
	getSellersFilterItems: any
	selectedSeller: any
}

export const PickSeller: FC<ISellerPicker> = ({
	getSellersFilterItems,
	selectedSeller,
}) => {
	return (
		<Dropdown
			trigger={['click']}
			placement={'bottomRight'}
			menu={{ items: getSellersFilterItems() }}
			overlayClassName={'dropdown-border'}
		>
			<div
				className='analytics-header-dropdown-v2'
				style={{
					width: '100%',
					height: '100%',
					padding: '10px 10px',
					display: 'flex',
					gap: 5,
					border:
						selectedSeller !== null && selectedSeller.length > 0
							? '1px solid #4c78ee'
							: '',
				}}
			>
				<div>Продавец</div>
				<Icon
					component={SelectIconComponent}
					style={{ marginTop: '2px', fontSize: '10px' }}
				/>
			</div>
		</Dropdown>
	)
}
