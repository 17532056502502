import AppLayout from 'features/layout/AppLayout'
import AccountPage from 'pages/account'
import AdvertisingMirrorPage from 'pages/advertising-mirror'
import AnalyticsPage from 'pages/analytics'
import CashbackInfoPage from 'pages/cashbacks/CashbackInfoPage'
import CashbacksPage from 'pages/cashbacks'
import CashbacksUsersPage from 'pages/cashbacks/CashbacksUsersPage'
import FinancialResultsPage from 'pages/financial-results'
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'
import StoragePage from 'pages/storage'
import CashbacksParticipantsPage from 'pages/cashbacks/CashbacksParticipantsPage'
import ProductsPage from '../../pages/products/ProductsPage'
import ProductsAnalyticsPage from '../../pages/products/ProductsAnalyticsPage'
import CashbackExpensesPage from '../../pages/cashbacks/CashbackExpensesPage'
import OrdersFromChinaPage from '../../pages/ordersFromChina'
import OperatingExpenses from '../../pages/operatingExpenses'
import DeclarationsTab from '../../pages/kiz/DeclarationsTab'
import EmissionsTab from '../../pages/kiz/EmissionsTab'
import ShipmentTab from '../../pages/kiz/ShipmentTab'
import ListOfShipmentFiles from '../../pages/kiz/ListOfShipmentFiles'
import TSDTab from '../../pages/kiz/TSDTab'
import Kiz from '../../pages/kiz/Kiz'
import EmissionsFullInfo from '../kiz/pages/emissions/EmissionsFullInfo'
import ListOfShipmentFilesFullInfo from '../kiz/pages/listOfShipmentFiles/ListOfShipmentFilesFullInfo'
import DeclarationsFullInfo from '../kiz/pages/declaration/DeclarationsFullInfo'
import AcceptanceTab from '../../pages/kiz/AcceptanceTab'
import ListOfAcceptanceFiles from '../../pages/kiz/ListOfAcceptanceFiles'
import ListOfAcceptanceFilesFullInfo from '../kiz/pages/listOfAcceptanceFiles/ListOfAcceptanceFilesFullInfo'
import TSDFullInfo from '../kiz/pages/tsd/TSDFullInfo'
import { useAuthStore } from '../auth/hooks/useAuthStore'
import StorekeepersTab from '../../pages/kiz/StorekeepersTab'
import StorekeepersFullInfo from '../kiz/pages/storekeepers/StorekeepersFullInfo'
import ListOfAcceptanceFilesFullInfoDeclaration from '../kiz/pages/listOfAcceptanceFiles/ListOfAcceptanceFilesFullInfoDeclaration'
import CostPricePage from '../../pages/costPrice'
import TSDExtraInfo from '../kiz/pages/tsd/TSDExtraInfo'
import Topology from '../../pages/storage/Topology'
import MapTab from '../../pages/kiz/MapTab'
import MarkingTab from '../../pages/kiz/MarkingTab'
import TasksTab from '../../pages/kiz/TasksTab'
import DocumentMirrorPage from '../../pages/document-mirror'
import DocumentMirrorStatement from '../../pages/document-mirror/DocumentMirrorStatement'
import DocumentMirrorDocuments from '../../pages/document-mirror/DocumentMirrorDocuments'
import ProductArrivalTab from '../../pages/storage/ProductArrivalTab'
import ShipmentsFormationTab from '../../pages/storage/ShipmentsFormationTab'
import AdditionalDeliveriesTab from '../../pages/storage/AdditionalDeliveriesTab'
import AddSkTab from '../../pages/storage/AddSkTab'
import ListOfSavedInvoicesTab from '../../pages/storage/ListOfSavedInvoicesTab'
import GTDTab from '../../pages/storage/GTDTab'
import GTDFullInfo from '../storage/pages/gtd/GTDFullInfo'
import AdvertisingMirrorTab from '../../pages/advertising-mirror/AdvertisingMirrorTab'
import IssuingPositionsTab from '../../pages/advertising-mirror/IssuingPositionsTab'
import InnovationProposalsPage from '../../pages/innovationProposals'
import QuestionsAndAnswersPage from '../../pages/questionsAndAnswers'
import ProductByPhraseTab from '../../pages/advertising-mirror/ProductByPhraseTab'
import BiderTab from '../../pages/advertising-mirror/BiderTab'
import BiderLogsTab from '../../pages/advertising-mirror/BiderLogsTab'
import ShipmentsPage from '../../pages/shipments'
import LaboratoryTVTPage from '../../pages/laboratoryTVT'
import ResultsLaboratory from '../../pages/laboratoryTVT/ResultsLaboratory'
import React from 'react'
import CurrentLaboratory from '../../pages/laboratoryTVT/CurrentLaboratory'
import AdsSelectionPage from '../../pages/adsSelection/AdsSelectionPage'
import AdsSelectionFullInfoPage from '../../pages/adsSelection/AdsSelectionFullInfoPage'
import MicroSeasons from '../../pages/microSeasons'
import SettingsMirrorPage from 'pages/settingsMirror'
import SettingsMirrorUsersDataPage from 'pages/settingsMirror/SettingsMirrorUsersData'
import SettingsMirrorApiAccessPage from 'pages/settingsMirror/SettingsMirrorApiAccess'
import SettingsMirrorSellersData from 'pages/settingsMirror/SettingsMirrorSellersData'
import SettingsMirrorSuppliesDataPage from 'pages/settingsMirror/SettingsMirrorSuppliessData'
import ActionsPage from 'pages/actions'
import ActionsCalendar from 'pages/actions/ActionsCalendar'
import ActionItemsData from 'pages/actions/ActionItemsData'
import NomenclatureDataPage from 'pages/actions/NomenclatureDataPage'
import ActionsDetails from 'pages/actions/ActionsDetails'
import ActionsChanges from 'pages/actions/ActionsChanges'
import PricesPage from 'pages/prices'
import CurrentPrices from 'features/prices/CurrentPrices'
import PricesUpload from 'pages/prices/PricesUpload'
import PlannedPrices from 'pages/prices/PlannedPrices'
import PricesHistory from 'pages/prices/PricesHistory'
import StatisticsPage from 'pages/advertising-mirror/StatisticsPage'
import { AdvertisingStatistics } from 'features/advertising-mirror/pages/advertisingStatistics/AdvertisingStatistics'
import ActionsBoost from 'pages/actions/ActionsBoost'
import UnitEconomicsPage from 'pages/unit-economics'
import SummaryPage from 'pages/unit-economics/Summary'
import DetailsPage from 'pages/unit-economics/Details'
import BuyoutsPage from 'pages/unit-economics/Buyouts'

export default function SecureRoutes() {
	const { state } = useAuthStore()

	const shouldDisplayRoute = (route: any) => {
		if (state?.user?.role?.name === 'Инвестор') {
			const displayedRoutes = [
				'financial-results',
				'account',
				'innovation-proposals',
				'questions-and-answers',
				'shipments',
			]
			return displayedRoutes.includes(route.path)
		}
		if (state?.user?.role?.name === 'МнМ-координатор') {
			const displayedRoutes = [
				'financial-results',
				'account',
				'innovation-proposals',
				'questions-and-answers',
				'shipments',
			]
			return displayedRoutes.includes(route.path)
		}
		if (state?.user?.role?.name === 'Бухгалтер') {
			const displayedRoutes = ['document-mirror', 'account', 'shipments']
			return displayedRoutes.includes(route.path)
		}
		if (state?.user?.role?.name === 'Аналитик') {
			const displayedRoutes = [
				'analytics',
				'advertising-mirror',
				'products',
				'account',
				'shipments',
			]
			return displayedRoutes.includes(route.path)
		}
		if (state?.user?.role?.name === 'Координатор') {
			const displayedRoutes = [
				'analytics',
				'cashbacks',
				'orders-from-china',
				'advertising-mirror',
				'operating-expenses',
				'document-mirror',
				'cost-price',
				'products',
				'account',
				'innovation-proposals',
				'storage',
				'questions-and-answers',
				'shipments',
				'actions',
				'prices',
			]
			return displayedRoutes.includes(route.path)
		}
		if (state?.user?.role?.name === 'Начальник склада') {
			const displayedRoutes = [
				'cost-price',
				'orders-from-china',
				'storage',
				'account',
				'shipments',
			]
			return displayedRoutes.includes(route.path)
		}
		if (state?.user?.role?.name === 'Кешбек-аналитик') {
			const displayedRoutes = ['cashbacks', 'account', 'shipments']
			return displayedRoutes.includes(route.path)
		}
		return true
	}

	const getFallbackPath = () => {
		switch (state?.user?.role?.name) {
			case 'Инвестор':
				return '/financial-results'
			case 'МнМ-координатор':
				return '/financial-results'
			case 'Бухгалтер':
				return '/document-mirror/statements'
			case 'Аналитик':
				return '/analytics'
			case 'Координатор':
				return '/analytics'
			case 'Начальник склада':
				return '/cashbacks'
			case 'Кешбек-аналитик':
				return '/cashbacks'
			default:
				return '/analytics'
		}
	}

	return (
		<Routes>
			<Route path={'/'} element={<AppLayout />}>
				{shouldDisplayRoute({ path: 'analytics' }) && (
					<Route path={'analytics'} element={<Outlet />}>
						<Route path={''} element={<AnalyticsPage />} />
						<Route path={'*'} element={<Navigate to={'/analytics'} />} />
					</Route>
				)}

				{shouldDisplayRoute({ path: 'shipments' }) && (
					<Route path={'shipments'} element={<Outlet />}>
						<Route path={''} element={<ShipmentsPage />} />
						<Route path={'*'} element={<Navigate to={'/shipments'} />} />
					</Route>
				)}

				{shouldDisplayRoute({ path: 'cashbacks' }) && (
					<Route path={'cashbacks'} element={<Outlet />}>
						<Route path={''} element={<CashbacksPage />} />
						<Route path={'users'} element={<CashbacksUsersPage />} />
						<Route
							path={'participants'}
							element={<CashbacksParticipantsPage />}
						/>
						<Route path={'expenses'} element={<CashbackExpensesPage />} />
						<Route path={':id'} element={<CashbackInfoPage />} />
						<Route path={'*'} element={<Navigate to={'/cashbacks'} />} />
					</Route>
				)}

				{/*{shouldDisplayRoute({ path: '' }) && (*/}
				{/*    <Route path="" element={<AnalyticsPage/>}/>*/}
				{/*)}*/}

				{shouldDisplayRoute({ path: 'storage' }) && (
					<Route path={'storage'} element={<StoragePage />}>
						<Route path={''} element={<ProductArrivalTab />} />

						<Route
							path={'shipments-formation'}
							element={<ShipmentsFormationTab />}
						/>
						<Route
							path={'additional-deliveries'}
							element={<AdditionalDeliveriesTab />}
						/>
						<Route path={'addSk'} element={<AddSkTab />} />
						<Route
							path={'list-of-saved-invoices'}
							element={<ListOfSavedInvoicesTab />}
						/>
						<Route path={'gtd'} element={<GTDTab />} />

						<Route path={'gtd'} element={<Outlet />}>
							<Route path={''} element={<GTDTab />} />
							<Route path={'full-info/:id'} element={<Outlet />}>
								<Route path={''} element={<GTDFullInfo />} />
							</Route>
						</Route>

						<Route path={'topology'} element={<Outlet />}>
							<Route path={''} element={<Topology />} />
						</Route>

						<Route path={''} element={<Kiz />} />

						<Route path={'emissions'} element={<Outlet />}>
							<Route path={''} element={<EmissionsTab />} />
							<Route path={'full-info/:id'} element={<EmissionsFullInfo />} />
						</Route>

						<Route path={'declaration'} element={<Outlet />}>
							<Route path={''} element={<DeclarationsTab />} />
							<Route
								path={'full-info/:id'}
								element={<DeclarationsFullInfo />}
							/>
						</Route>

						<Route path={'shipment'} element={<ShipmentTab />} />

						<Route path={'list-of-shipment-files'} element={<Outlet />}>
							<Route path={''} element={<ListOfShipmentFiles />} />
							<Route
								path={'full-info/:id'}
								element={<ListOfShipmentFilesFullInfo />}
							/>
						</Route>

						<Route path={'acceptance'} element={<AcceptanceTab />} />

						<Route path={'list-of-acceptance-files'} element={<Outlet />}>
							<Route path={''} element={<ListOfAcceptanceFiles />} />
							<Route path={'full-info/:id'} element={<Outlet />}>
								<Route path={''} element={<ListOfAcceptanceFilesFullInfo />} />
								<Route
									path={'declaration/:did'}
									element={<ListOfAcceptanceFilesFullInfoDeclaration />}
								/>
							</Route>
						</Route>

						<Route path={'gtd'} element={<Outlet />}>
							<Route path={''} element={<TSDTab />} />
							<Route path={'full-info/:id'} element={<Outlet />}>
								<Route path={''} element={<TSDFullInfo />} />
								<Route path={'extra-info'} element={<TSDExtraInfo />} />
							</Route>
						</Route>

						<Route path={'storekeepers'} element={<Outlet />}>
							<Route path={''} element={<StorekeepersTab />} />
							<Route path={'full-info/:id'} element={<Outlet />}>
								<Route path={''} element={<StorekeepersFullInfo />} />
								<Route
									path={'declaration/:did'}
									element={<ListOfAcceptanceFilesFullInfoDeclaration />}
								/>
							</Route>
						</Route>

						<Route path={'topology'} element={<Outlet />}>
							<Route path={''} element={<MarkingTab />} />
						</Route>

						<Route path={'tasks'} element={<Outlet />}>
							<Route path={''} element={<TasksTab />} />
						</Route>

						<Route path={'map'} element={<Outlet />}>
							<Route path={''} element={<MapTab />} />
						</Route>

						<Route path={'test'} element={<Kiz />} />

						<Route path={'emissions'} element={<Outlet />}>
							<Route path={''} element={<EmissionsTab />} />
							<Route path={'full-info/:id'} element={<EmissionsFullInfo />} />
						</Route>

						<Route path={'declaration'} element={<Outlet />}>
							<Route path={''} element={<DeclarationsTab />} />
							<Route
								path={'full-info/:id'}
								element={<DeclarationsFullInfo />}
							/>
						</Route>

						<Route path={'shipment'} element={<ShipmentTab />} />

						<Route path={'list-of-shipment-files'} element={<Outlet />}>
							<Route path={''} element={<ListOfShipmentFiles />} />
							<Route
								path={'full-info/:id'}
								element={<ListOfShipmentFilesFullInfo />}
							/>
						</Route>

						<Route path={'acceptance'} element={<AcceptanceTab />} />

						<Route path={'list-of-acceptance-files'} element={<Outlet />}>
							<Route path={''} element={<ListOfAcceptanceFiles />} />
							<Route path={'full-info/:id'} element={<Outlet />}>
								<Route path={''} element={<ListOfAcceptanceFilesFullInfo />} />
								<Route
									path={'declaration/:did'}
									element={<ListOfAcceptanceFilesFullInfoDeclaration />}
								/>
							</Route>
						</Route>

						<Route path={'tsd'} element={<Outlet />}>
							<Route path={''} element={<TSDTab />} />
							<Route path={'full-info/:id'} element={<Outlet />}>
								<Route path={''} element={<TSDFullInfo />} />
								<Route path={'extra-info'} element={<TSDExtraInfo />} />
							</Route>
						</Route>

						<Route path={'storekeepers'} element={<Outlet />}>
							<Route path={''} element={<StorekeepersTab />} />
							<Route path={'full-info/:id'} element={<Outlet />}>
								<Route path={''} element={<StorekeepersFullInfo />} />
								<Route
									path={'declaration/:did'}
									element={<ListOfAcceptanceFilesFullInfoDeclaration />}
								/>
							</Route>
						</Route>

						<Route path={'marking'} element={<Outlet />}>
							<Route path={''} element={<MarkingTab />} />
						</Route>

						<Route path={'tasks'} element={<Outlet />}>
							<Route path={''} element={<TasksTab />} />
						</Route>

						<Route path={'map'} element={<Outlet />}>
							<Route path={''} element={<MapTab />} />
						</Route>
					</Route>
				)}

				{shouldDisplayRoute({ path: 'advertising-mirror' }) && (
					<Route
						path={'advertising-mirror'}
						element={<AdvertisingMirrorPage />}
					>
						<Route path={''} element={<AdvertisingMirrorTab />} />
						<Route path={'statistics'} element={<AdvertisingStatistics />} />
						<Route
							path={'issuing-positions'}
							element={<IssuingPositionsTab />}
						/>
						{/*<Route path={"phrase-plus"} element={<PhrasePlusTab/>}/>*/}
						<Route
							path={'product-by-phrase'}
							element={<ProductByPhraseTab />}
						/>
						<Route path={'bider'} element={<BiderTab />} />
						<Route path={'bider-logs'} element={<BiderLogsTab />} />
					</Route>
				)}

				{shouldDisplayRoute({ path: 'account' }) && (
					<Route path={'account'} element={<Outlet />}>
						<Route path={''} element={<AccountPage />} />
					</Route>
				)}

				{shouldDisplayRoute({ path: 'financial-results' }) && (
					<Route path={'financial-results'} element={<Outlet />}>
						<Route path={''} element={<FinancialResultsPage />} />
					</Route>
				)}

				{shouldDisplayRoute({ path: 'products' }) && (
					<Route path={'products'} element={<Outlet />}>
						<Route path={'products'} element={<ProductsPage />} />
						<Route path={'analytics'} element={<ProductsAnalyticsPage />} />
					</Route>
				)}

				{shouldDisplayRoute({ path: 'orders-from-china' }) && (
					<Route path={'orders-from-china'} element={<Outlet />}>
						<Route path={''} element={<OrdersFromChinaPage />} />
					</Route>
				)}

				{shouldDisplayRoute({ path: 'operating-expenses' }) && (
					<Route path={'operating-expenses'} element={<Outlet />}>
						<Route path={''} element={<OperatingExpenses />} />
					</Route>
				)}

				{shouldDisplayRoute({ path: 'cost-price' }) && (
					<Route path={'cost-price'} element={<Outlet />}>
						<Route path={''} element={<CostPricePage />} />
					</Route>
				)}

				{shouldDisplayRoute({ path: 'document-mirror' }) && (
					<Route path={'document-mirror'} element={<DocumentMirrorPage />}>
						<Route path={'statements'} element={<DocumentMirrorStatement />} />
						<Route path={'documents'} element={<DocumentMirrorDocuments />} />
					</Route>
				)}

				{shouldDisplayRoute({ path: 'innovation-proposals' }) && (
					<Route
						path={'innovation-proposals'}
						element={<InnovationProposalsPage />}
					/>
				)}

				{shouldDisplayRoute({ path: 'questions-and-answers' }) && (
					<Route
						path={'questions-and-answers'}
						element={<QuestionsAndAnswersPage />}
					/>
				)}

				{shouldDisplayRoute({ path: 'laboratory-tvt' }) && (
					<Route path={'laboratory-tvt'} element={<LaboratoryTVTPage />}>
						<Route path={''} element={<CurrentLaboratory />} />
						<Route path={'results'} element={<ResultsLaboratory />} />
					</Route>
				)}

				{shouldDisplayRoute({ path: 'ads-selection' }) && (
					<Route path={'adsSelection'} element={<Outlet />}>
						<Route path={''} element={<AdsSelectionPage />} />
						<Route
							path={'full-info/:id'}
							element={<AdsSelectionFullInfoPage />}
						/>
					</Route>
				)}

				{shouldDisplayRoute({ path: 'micro-seasons' }) && (
					<Route path={'microSeasons'} element={<Outlet />}>
						<Route path={''} element={<MicroSeasons />} />
					</Route>
				)}

				{shouldDisplayRoute({ path: 'settings-mirror' }) && (
					<Route path={'settings-mirror'} element={<SettingsMirrorPage />}>
						<Route
							path={'sellers-data'}
							element={<SettingsMirrorSellersData />}
						/>
						<Route path={'users'} element={<SettingsMirrorUsersDataPage />} />
						<Route
							path={'api-access'}
							element={<SettingsMirrorApiAccessPage />}
						/>
						<Route
							path={'supplies'}
							element={<SettingsMirrorSuppliesDataPage />}
						/>
					</Route>
				)}

				{shouldDisplayRoute({ path: 'actions' }) && (
					<Route path='actions' element={<ActionsPage />}>
						<Route path='actions-calendar' element={<Outlet />}>
							<Route path='' element={<ActionsCalendar />} />
							<Route path=':id' element={<ActionItemsData />} />
						</Route>
						<Route path='nomenclature' element={<Outlet />}>
							<Route path=':id' element={<NomenclatureDataPage />} />
						</Route>
						<Route path='actions-details' element={<ActionsDetails />} />
						<Route path='actions-boost' element={<ActionsBoost />} />
						<Route path='changes' element={<ActionsChanges />} />
					</Route>
				)}

				{shouldDisplayRoute({ path: 'prices' }) && (
					<Route path='prices' element={<PricesPage />}>
						<Route path='current' element={<Outlet />}>
							<Route path='' element={<CurrentPrices />} />
							<Route path=':id' element={<ActionItemsData />} />
						</Route>
						<Route path='history' element={<Outlet />}>
							<Route path='' element={<PricesHistory />} />
							<Route path=':id' element={<NomenclatureDataPage />} />
						</Route>
						<Route path='planned' element={<PlannedPrices />} />
						<Route path='upload' element={<PricesUpload />} />
					</Route>
				)}

				{shouldDisplayRoute({ path: 'unit-economics' }) && (
					<Route path='unit-economics' element={<UnitEconomicsPage />}>
						<Route path='summary' element={<Outlet />}>
							<Route path='' element={<SummaryPage />} />
						</Route>
						<Route path='details' element={<Outlet />}>
							<Route path='' element={<DetailsPage />} />
						</Route>
						<Route path='buyouts' element={<BuyoutsPage />} />
					</Route>
				)}

				<Route path='/' element={<Navigate to={getFallbackPath()} />} />
			</Route>

			<Route path='*' element={<Navigate to={getFallbackPath()} />} />
		</Routes>
	)
}
