import { Col, Row } from 'antd'
import BuyoutsTable from 'features/unitEconomics/BuyoutsTable'
import React from 'react'

export default function BuyoutsPage() {
	return (
		<Row style={{ padding: '10px 0' }}>
			<Col className={'container'} span={24}>
				<BuyoutsTable />
			</Col>
		</Row>
	)
}
