import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    Input,
    Row,
    Select,
    Table,
    DatePicker,
    Modal, Dropdown, MenuProps, Menu, message,
} from "antd";
import {ReactComponent as SelectIconComponent} from "assets/images/select-icon.svg";
import {ReactComponent as filterIcon} from "assets/images/filter-icon.svg";
import {ReactComponent as editIcon} from "assets/images/edit-icon.svg";
import Icon, {DownloadOutlined, LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import useCostPriceTableFilters from "./hooks/useCostPriceTableFilters";
import {ReactComponent as trashBucket} from "../../assets/images/trash-bucket.svg";
import ConfirmModal from "../advertising-mirror/modal/advertisingMirror/ConfirmModal";
import useCostPriceData from "./hooks/useCostPriceData";
import AddCostPriceModal from "./modal/AddCostPriceModal";
import EditCostPriceModal from "./modal/EditCostPriceModal";
import {useDeleteCostPrice} from "./hooks/useDeleteCostPrice";
import dayjs from "dayjs";
import AddFileModal from "./modal/AddFileModal";

const {RangePicker} = DatePicker;

export default function CostPriceTable() {

    const {
        costPriceData,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        setSortParam,
        setSortType,
        getSellersFilterItems,
        handleRangeChangeTable,
        isLoading,
				searchText,
				setSearchText
    } = useCostPriceData()

    const {
        handleDelete,
        errorMessage,
        isLoading: isLoadingDelete,
        isSuccess: isSuccessDelete,
        isError: isErrorDelete
    } = useDeleteCostPrice()


    const [addNoteModal, setAddNoteModal] = useState<any>(false);
    const [addFileModal, setAddFileModal] = useState<any>(false);
    const [editNoteModal, setEditNoteModal] = useState<any>({
        isOpen: false,
        id: null
    });
    const [isModalVisible, setIsModalVisible] = useState<any>({
        isOpen: false,
        text: '',
        onPress: () => {}
    });

    const [filterMenuOpened, setFilterMenuOpened] = useState<boolean>(false);
    const {filterMenuItems} = useCostPriceTableFilters();

    const productsItemsForEdit: MenuProps["items"] = [
        {
            label: (
                <span style={{display: "flex", gap: "10px"}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Редактировать
                </span>
            ),
            key: "EDIT",
        },
        {
            label: (
                <span
                    style={{
                        display: "flex",
                        gap: "10px",
                        color: isLoadingDelete ? '#e0e0e0' : 'red',
                        width: 180,
                        pointerEvents: isLoadingDelete ? 'none' : 'auto'
                    }}>
                    <Icon component={trashBucket} style={{cursor: "pointer"}}/>
                    Удалить
                </span>
            ),
            key: "DELETE",
        }
    ];

    const getProductsActions = (record: any) => {
        return {
            items: productsItemsForEdit,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "EDIT":
                        setEditNoteModal({
                            isOpen: true,
                            id: record?.id,
                            productId: record?.productId
                        })
                        break;
                    case "DELETE":
                        setIsModalVisible({
                            isOpen: true,
                            text: 'удалить запись?',
                            onPress: () => handleDelete(record?.id)
                        })
                        break;
                }
            },
        };
    };

    const columns = [
        {
            title: "Фото",
            dataIndex: "photo",
            key: "photo",
            width: "9%",
            render: (text: any, record: any) => {
                return (
                    <div style={{textAlign: "center"}}>
                        <img src={text} style={{width: "70px"}} alt=""></img>{" "}
                    </div>
                );
            },
        },
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            width: "9%",
        },
        {
            title: "Стоимость руб",
            dataIndex: "cost",
            key: "cost",
            width: "9%",
        },
        {
            title: "Артикул",
            dataIndex: "wbArticle",
            key: "wbArticle",
            width: "9%",
        },
        {
            title: "Размер",
            dataIndex: "size",
            key: "size",
            width: "9%",
        },
        {
            title: "Кол-во",
            dataIndex: "count",
            key: "count",
            width: "9%",
        },
        {
            title: "Продано",
            dataIndex: "sold",
            key: "sold",
            width: "9%",
        },
        {
            title: "Юр.лицо",
            dataIndex: "sellerName",
            key: "sellerName",
            width: "9%",
        },
        {
            title: "ГТД",
            dataIndex: "numberDT",
            key: "numberDT",
            width: "9%",
        },
        {
            title: "Дата начала",
            dataIndex: "appearedFrom",
            key: "appearedFrom",
            width: "9%",
            render: (text: any, record: any) =>{
                return(
                    <div>
                        {dayjs(text).format("DD.MM.YYYY")}
                    </div>
                )
            }
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (text: any, record: any) => (
                <div
                    style={{
                        cursor: "pointer",
                    }}
                >
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={getProductsActions(record)}
                    >
                        <MoreOutlined
                            style={{cursor: "pointer", fontSize: "20px"}}/>
                    </Dropdown>
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (isSuccessDelete) {
            message.success('Вы успешно удалили себестоимость')
        }
        if (isErrorDelete) {
            message.error(errorMessage)
        }
    }, [isErrorDelete, isSuccessDelete])


    return (
			<div style={{ backgroundColor: '#f8f8f8' }}>
				<Row gutter={6} align={'middle'}>
					<Col style={{ flexGrow: '1' }}>
						<Input
							value={searchText}
							onChange={(e) => setSearchText(e.target.value)}
							className='table-header-input'
							placeholder={'Поиск'}
							type={"number"}
						/>
					</Col>

					<Col style={{ width: '160px', paddingTop: 0, paddingBottom: 0 }}>
						<Col>
							<Dropdown
								trigger={['click']}
								placement={'bottomRight'}
								menu={{ items: getSellersFilterItems() }}
								overlayClassName={'dropdown-border'}
							>
								<div className={'analytics-header-dropdown '}>
									<div>Юр. лицо</div>
									<Icon
										component={SelectIconComponent}
										style={{ marginTop: '2px', fontSize: '10px' }}
									/>
								</div>
							</Dropdown>
						</Col>
					</Col>
					<Col style={{ width: '190px' }}>
						<Select
							className='table-header-select table-header-select-no-offset'
							suffixIcon={<Icon component={SelectIconComponent} />}
							defaultValue={currentPageSize}
							onChange={e => {
								setCurrentPage(1)
								setCurrentPageSize(e)
							}}
							options={[
								{ value: '10', label: 'Показать по 10 шт.' },
								{ value: '50', label: 'Показать по 50 шт.' },
								{ value: '100', label: 'Показать по 100 шт.' },
							]}
						/>
					</Col>

					<Col>
						<Button
							href='https://it-korobka.ru/api/storage/selfcost/selfcost_sample.xlsx'
							type={'primary'}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
                    <DownloadOutlined />
							Скачать шаблон
						</Button>
					</Col>

					<Col>
                    
						<Button
							onClick={() => setAddFileModal(true)}
							type={'primary'}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							Добавить файл себестоимости
						</Button>
					</Col>
					<Col>
						<Button
							onClick={() => setAddNoteModal(true)}
							className='table-header-button'
							type={'primary'}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							Добавить себестоимость
						</Button>
					</Col>
				</Row>
				<Row className={'container'} style={{ marginTop: '10px' }}>
					<Col span={24}>
						<Row
							justify={'space-between'}
							align={'middle'}
							style={{ padding: '5px 30px' }}
						>
							<Col>
								<div style={{ position: 'relative' }}>
									<div
										style={{ cursor: 'pointer' }}
										onClick={() => {
											setFilterMenuOpened(!filterMenuOpened)
										}}
									>
										<Icon component={filterIcon} />
										<span
											style={{
												color: '#82868B',
												marginLeft: '6px',
											}}
										>
											Фильтры
										</span>
									</div>

									<Menu
										className={
											filterMenuOpened
												? 'filter-menu filter-menu-opened'
												: 'filter-menu filter-menu-closed'
										}
										style={{
											color: 'red !important',
											width: '250px',
											left: '-40%',
										}}
										items={filterMenuItems}
										mode={'inline'}
										onClick={e => {
											const key = e.key
											const test = key.split('-')
											setSortParam(test[0])
											setSortType(test[1])
										}}
									/>
								</div>
							</Col>
							<Col>
								<RangePicker
									onChange={handleRangeChangeTable}
									placeholder={['Начало', 'Конец']}
									style={{ width: '230px' }}
									format={'DD.MM.YYYY'}
								/>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Table
							loading={isLoading}
							className={'product-arrival-table'}
							columns={columns}
							dataSource={costPriceData?.data ?? []}
							scroll={{ x: true }}
							pagination={{
								onChange: (page, pageSize): any => setCurrentPage(page),
								position: ['bottomCenter'],
								pageSize: Number(currentPageSize),
								total: Number(costPriceData?.total),
								showSizeChanger: false,
								current: currentPage,
							}}
						/>
					</Col>
				</Row>

				<Modal open={addNoteModal} closable={false} footer={null} width={600}>
					<AddCostPriceModal onClose={() => setAddNoteModal(false)} />
				</Modal>
				<Modal open={addFileModal} closable={false} footer={null} width={600}>
					<AddFileModal onClose={() => setAddFileModal(false)} />
				</Modal>
				<Modal
					open={editNoteModal?.isOpen}
					closable={false}
					footer={null}
					width={600}
				>
					<EditCostPriceModal
						id={editNoteModal?.id}
						productId={editNoteModal?.productId}
						onClose={() =>
							setEditNoteModal({
								isOpen: false,
								id: null,
							})
						}
					/>
				</Modal>
				<Modal
					open={isModalVisible.isOpen}
					closable={false}
					footer={null}
					width={500}
				>
					<ConfirmModal
						onClose={() =>
							setIsModalVisible({
								isOpen: false,
								onPress: () => {},
							})
						}
						onConfirm={() => isModalVisible.onPress()}
						text={isModalVisible.text}
					/>
				</Modal>
			</div>
		)
}
