import { api, apiKiz } from 'index'
import dayjs from 'dayjs'
import { message } from 'antd'

// Requests
export async function getAdvertisingMirrorData(
	sellerId?: string,
	offSet?: string,
	pageSize?: string,
	selectedTab?: string,
	selectedType?: string
) {
	let params = new URLSearchParams()

	if (pageSize) {
		params.append('limit', pageSize)
	}
	if (offSet) {
		params.append('offset', offSet)
	}

	if (selectedTab === '0') {
	} else {
		if (selectedTab != null) {
			params.append('status', selectedTab)
		}
	}
	if (selectedType === '0') {
	} else {
		if (selectedType != null) {
			params.append('type', selectedType)
		}
	}

	if (sellerId) {
		params.append('seller_id', sellerId)
		const response = await api.get<any>(`/ads-campaigns/adverts`, {
			params,
		})
		return response.data.data
	}
}

// Requests
export async function getIssuingPositionsData(
	dateStart: string,
	dateEnd: string,
	currentPage?: string,
	currentPageSize?: number,
	sellers?: string,
	products?: string[],
	type?: string,
	debouncedSearchArticle?: string,
	isWithElementsInTable?: boolean,
	isPlusMinusPhraseMode?: boolean
) {
	let params = new URLSearchParams()

	if (currentPage) {
		params.append('page', currentPage)
	}

	if (currentPageSize) {
		params.append('limit', currentPageSize.toString())
	}

	if (type) {
		params.append('type', type.toString())
	}

	// @ts-ignore
	params.append('without_empty', isWithElementsInTable)
	// @ts-ignore
	params.append('by_plus_minus_phrases', isPlusMinusPhraseMode)

	if (debouncedSearchArticle) {
		params.append('article', debouncedSearchArticle)
	}

	if (dateStart && dateEnd) {
		params.append(
			'period_start_date',
			dayjs(dateStart).format('DD.MM.YYYY HH:mm')
		)
		params.append('period_end_date', dayjs(dateEnd).format('DD.MM.YYYY HH:mm'))
	}

	if (products && products.length > 0) {
		params.append(
			'categories_ids',
			products?.map((item: any) => item?.id)?.join(',')
		)
	}

	if (sellers) {
		params.append('seller_id', sellers)

		const response = await api.get<any>(`/keywordsV2/products/stats`, {
			params,
		})
		return response.data.data
	}
}

export async function getBiderData(
	currentPage?: string,
	currentPageSize?: number,
	sellers?: string,
	products?: string[],
	debouncedSearchArticle?: string
) {
	let params = new URLSearchParams()

	if (currentPage) {
		params.append('page', currentPage)
	}

	if (currentPageSize) {
		params.append('limit', currentPageSize.toString())
	}

	if (debouncedSearchArticle) {
		params.append('article', debouncedSearchArticle)
	}

	if (products && products.length > 0) {
		params.append(
			'categories_ids',
			products?.map((item: any) => item?.id)?.join(',')
		)
	}

	if (sellers) {
		params.append('seller_id', sellers)

		const response = await api.get<any>(`/bider/stats`, {
			params,
		})
		return response.data.data
	}
}

export async function getBiderLogsData(
	currentPage?: string,
	currentPageSize?: number,
	sellers?: string,
	debouncedSearchArticle?: string
) {
	let params = new URLSearchParams()

	if (currentPage) {
		params.append('page', currentPage)
	}

	if (currentPageSize) {
		params.append('limit', currentPageSize.toString())
	}

	if (debouncedSearchArticle) {
		params.append('article', debouncedSearchArticle)
	}

	if (sellers) {
		params.append('seller_id', sellers)

		const response = await api.get<any>(`/bider/logs`, {
			params,
		})
		return response.data.data
	}
}

export async function changeStatusOfProduct(body: any) {
	const { inAds, id, datetime } = body
	let params = new URLSearchParams()

	params.append('in_ads', String(!!inAds))

	// if (datetime){
	//     params.append("start_time", datetime);
	// }

	if (id) {
		const response = await api.post<any>(
			`/productsV2/${id}/ads`,
			{},
			{ params }
		)
		return response.data.data
	}
}

export async function getAdvertisingMirrorDataCount(selectedSeller?: string) {
	let params = new URLSearchParams()

	if (selectedSeller) {
		params.append('seller_id', selectedSeller)
		const response = await api.get<any>(`/ads-campaigns/count`, { params })
		return response.data.data
	}
}

export async function mirrorStartStatus(id: string) {
	let params = new URLSearchParams()
	params.append('id', id)
	console.log('here' + id)

	if (id) {
		const response = await api.get<any>(`/ads-campaignsadverts/${id}/start`)
		return response.data.data
	}
}

export async function mirrorPauseStatus(id: string) {
	let params = new URLSearchParams()
	params.append('id', id)

	if (id) {
		const response = await api.get<any>(`/ads-campaignsadverts/${id}/pause`)
		return response.data.data
	}
}

export async function mirrorStopStatus(id: string) {
	let params = new URLSearchParams()
	params.append('id', id)

	if (id) {
		const response = await api.get<any>(`/ads-campaignsadverts/${id}/stop`)
		return response.data.data
	}
}

export async function getStatsData(
	dateStart: string,
	dateEnd: string,
	currentPage?: string,
	currentPageSize?: number,
	sellers?: string[],
	products?: string[],
	debouncedSearchArticle?: string,
	tracking?: boolean | null
) {
	let params = new URLSearchParams()

	if (currentPage) {
		params.append('page', currentPage)
	}

	if (currentPageSize) {
		params.append('limit', currentPageSize.toString())
	}

	if (debouncedSearchArticle) {
		params.append('search', debouncedSearchArticle)
	}

	if (dateStart && dateEnd) {
		params.append('start_date', dayjs(dateStart).format('DD.MM.YYYY'))
		params.append('end_date', dayjs(dateEnd).format('DD.MM.YYYY'))
	}

	if (products && products.length > 0) {
		params.append(
			'categories',
			products?.map((item: any) => item?.id)?.join(',')
		)
	}

	if (sellers && sellers.length > 0) {
		params.append('sellers', sellers?.map((item: any) => item?.id)?.join(','))
	}

	if (tracking !== null) {
		params.append('tracking', `${tracking}`)
	}

	const response = await api.get<any>(`/ads-campaigns/stats`, {
		params,
	})
	return response.data.data
}

export async function addComment(product_id: string | number, comment: string) {
	const params = new URLSearchParams()
	console.log(product_id, comment)
	if (product_id) {
		params.append('product_id', product_id.toString())
	}
	if (comment) {
		params.append('comment', comment)
	}
	const queryString = params.toString().replace(/\+/g, '%20')

	const response = await api.post<any>(`/ads-campaigns/comment?${queryString}`)
	return response.data.data
}

export async function addDateComment(
	product_id: string | number,
	comment: string,
	date?: string,
	weekId?: number,
	avgWeekId?: number
) {
	const params = new URLSearchParams()
	console.log(product_id, comment)
	if (date) {
		params.append('date', date)
	}
	if (weekId) {
		params.append('week_id', weekId.toString())
	}
	if (avgWeekId) {
		params.append('week_avg_id', avgWeekId.toString())
	}
	if (product_id) {
		params.append('product_id', product_id.toString())
	}
	if (comment) {
		params.append('comment', comment)
	}
	const queryString = params.toString().replace(/\+/g, '%20')

	const response = await api.post<any>(`/ads-campaigns/date-comment?${queryString}`)
	return response.data.data
}

export async function planOrders(
	product_id: string | number,
	min: number,
	max: number
) {
	const params = new URLSearchParams()
	if (product_id) {
		params.append('product_id', product_id.toString())
	}
	if (min && max) {
		params.append('min', min.toString())
		params.append('max', max.toString())
	}
	const queryString = params.toString().replace(/\+/g, '%20')

	const response = await api.post<any>(
		`/ads-campaigns/plan_orders?${queryString}`
	)
	return response.data.data
}

export async function productStatus(
	product_id: string | number,
	actionStatus: string,
	productStatus: string
) {
	const params = new URLSearchParams()
	if (product_id) {
		params.append('product_id', product_id.toString())
	}
	if (actionStatus) {
		params.append('action_status', actionStatus)
	}
	if (productStatus) {
		params.append('product_status', productStatus)
	}
	const queryString = params.toString().replace(/\+/g, '%20')

	const response = await api.post<any>(
		`/ads-campaigns/product_status?${queryString}`
	)
	return response.data.data
}

export async function adStatus(
	product_id: string | number,
	adType: string,
	adRunStatus: string
) {
	const params = new URLSearchParams()
	if (product_id) {
		params.append('product_id', product_id.toString())
	}
	if (adType) {
		params.append('ad_type', adType)
	}
	if (adRunStatus) {
		params.append('ad_run_status', adRunStatus)
	}
	const queryString = params.toString().replace(/\+/g, '%20')

	const response = await api.post<any>(
		`/ads-campaigns/ad_status?${queryString}`
	)
	return response.data.data
}

export async function importCometa(file: File) {
	try {
		const params = new URLSearchParams()

		const formData = new FormData()
		formData.append('file', file)
		const queryString = params.toString().replace(/\+/g, '%20')

		const response = await api.post<any>(
			`/ads-campaigns/import-cometa${queryString ? `?${queryString}` : ''}`,
			formData
		)
		return response.data
	} catch (error: any) {
		console.error(`Error uploading nomenclatures excel:`, error)
		message.error('Критическая ошибка отправки файла. Попробуйте позже.')
		throw error
	}
}

export async function downloadAdsStatsExcel(
	dateStart: string,
	dateEnd: string,
	currentPage?: string,
	currentPageSize?: number,
	sellers?: string[],
	products?: string[],
	debouncedSearchArticle?: string,
	tracking?: boolean | null
) {
	const params = new URLSearchParams()
	try {
		if (currentPage) {
			params.append('page', currentPage)
		}

		if (currentPageSize) {
			params.append('limit', currentPageSize.toString())
		}

		if (debouncedSearchArticle) {
			params.append('search', debouncedSearchArticle)
		}

		if (dateStart && dateEnd) {
			params.append('start_date', dayjs(dateStart).format('DD.MM.YYYY'))
			params.append('end_date', dayjs(dateEnd).format('DD.MM.YYYY'))
		}

		if (products && products.length > 0) {
			params.append(
				'categories',
				products?.map((item: any) => item?.id)?.join(',')
			)
		}

		if (sellers && sellers.length > 0) {
			params.append('sellers', sellers?.map((item: any) => item?.id)?.join(','))
		}

		if (tracking !== null) {
			params.append('tracking', `${tracking}`)
		}

		const queryString = params.toString().replace(/\+/g, '%20')
		const response = await api.get<any>(
			`ads-campaigns/download?${queryString}`,
			{
				responseType: 'blob',
			}
		)
		return response.data
	} catch (error: any) {
		console.error(`Error downloading nomenclatures excel:`, error)
		message.error(`Критическая ошибка выгрузки. Попробуйте позже`)
		throw error
	}
}
