import { useMutation, useQuery, useQueryClient } from 'react-query'
import { backend } from 'api'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { Checkbox, Input, MenuProps, message } from 'antd'
import { ReactComponent as searchIcon } from '../../../../assets/images/search-icon.svg'
import Icon, { LoadingOutlined, MoreOutlined } from '@ant-design/icons'
import useSellerList from '../../../sellers/hooks/useSellerList'
import useProductList from '../../../product/hooks/useProductList'
import { useDebounce } from 'use-debounce'

export default function useAdStats() {
	const queryClient = useQueryClient()
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [currentPageSize, setCurrentPageSize] = useState<number>(10)
	const [selectedSeller, setSelectedSeller] = useState<string[]>([])
	const [selectedProducts, setSelectedProducts] = useState<Array<string>>([])
	const [isWithElementsInTable, setIsWithElementsInTable] =
		useState<boolean>(false)
	const [isPlusMinusPhraseMode, setIsPlusMinusPhraseMode] =
		useState<boolean>(false)
	const [type, setType] = useState<string>('all')
	const [searchArticle, setSearchArticle] = useState<string>('')
	const [debouncedSearchArticle] = useDebounce(searchArticle, 1000)
	const [dataType, setDataType] = useState<'adsStats' | null>(null)
	const [selectedAvailableType, setSelectedAvailableType] = useState<
		boolean | null
	>(null)

	const getPrevWeek = (today: any = dayjs()) => {
		return today.subtract(14, 'day')
	}

	const prevWeek = getPrevWeek()
	const today = dayjs()

	const [dateStart, setDateStart] = useState<any>(prevWeek)
	const [dateEnd, setDateEnd] = useState<any>(today)

	const handleRangeChangeTable = (dateStrings: any) => {
		setDateStart(dateStrings[0])
		setDateEnd(dateStrings[1])
	}

	const {
		sellers,
		search: searchSellers,
		setSearch: setSearchSellers,
	} = useSellerList()

	const {
		products,
		search: searchProduct,
		setSearch: setSearchProduct,
	} = useProductList()

	const { data: statsData, ...options } = useQuery(
		[
			'ADS_STATS_LIST',
			dateStart,
			dateEnd,
			currentPage,
			currentPageSize,
			selectedSeller,
			selectedProducts,
			debouncedSearchArticle,
			selectedAvailableType,
		],
		() =>
			backend.advertisingMirror.getStatsData(
				dateStart,
				dateEnd,
				currentPage.toString(),
				currentPageSize,
				selectedSeller,
				selectedProducts,
				debouncedSearchArticle,
				selectedAvailableType
			),
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
		}
	)

	const getProductsFilterItems = () => {
		if (!products) {
			return []
		}

		let allItems: any[] = []
		let selectedItems: any[] = []

		products?.forEach((item: any) => {
			const isDuplicate = selectedProducts.some(
				(productItem: any) =>
					productItem?.id?.toString() === item?.id?.toString()
			)

			if (!isDuplicate) {
				allItems.push({
					label: (
						<div
							style={{ display: 'flex', gap: '10px' }}
							onClick={e => {
								e.stopPropagation()
							}}
						>
							<Checkbox
								style={{ width: '100%' }}
								value={item.title}
								checked={selectedProducts.some(
									(productItems: any) =>
										productItems?.id?.toString() === item?.id?.toString()
								)}
								onChange={e => {
									let newSelectedProducts = [...selectedProducts]
									if (e.target.checked) {
										newSelectedProducts.push(item)
									} else {
										newSelectedProducts = newSelectedProducts.filter(
											(el: any) => el?.id?.toString() !== item?.id?.toString()
										)
									}
									setSelectedProducts(newSelectedProducts)
								}}
							>
								{item.title}
							</Checkbox>
						</div>
					),
					key: `product-${item.id}`,
				})
			}
		})

		selectedProducts?.forEach((item: any) => {
			const isDuplicate = selectedProducts.some(
				(productItem: any) =>
					productItem?.id?.toString() === item?.id?.toString()
			)

			if (isDuplicate) {
				selectedItems.push({
					label: (
						<div
							style={{ display: 'flex', gap: '10px' }}
							onClick={e => {
								e.stopPropagation()
							}}
						>
							<Checkbox
								style={{ width: '100%' }}
								value={item.title}
								checked={selectedProducts.some(
									(productItems: any) =>
										productItems?.id?.toString() === item?.id?.toString()
								)}
								onChange={e => {
									let newSelectedProducts = [...selectedProducts]
									if (e.target.checked) {
										newSelectedProducts.push(item)
									} else {
										newSelectedProducts = newSelectedProducts.filter(
											(el: any) => el?.id?.toString() !== item?.id?.toString()
										)
									}
									setSelectedProducts(newSelectedProducts)
								}}
							>
								{item.title}
							</Checkbox>
						</div>
					),
					key: `product-${item.id}`,
				})
			}
		})

		const items: MenuProps['items'] = [
			{
				label: (
					<Input
						value={searchProduct}
						className={'analytic-dropdown-input'}
						onClick={e => e?.stopPropagation()}
						onChange={e => setSearchProduct(e?.target?.value)}
						prefix={<Icon component={searchIcon} />}
						placeholder={'Поиск...'}
					/>
				),
				key: 'product-search',
			},
			...selectedItems,
			...allItems,
		]

		return items
	}

	const getSellersFilterItems = () => {
		if (!sellers) {
			return []
		}

		let allItems: any[] = []
		let selectedItems: any[] = []

		const handleCheckboxChange = (item: any, checked: boolean) => {
			let newSelectedSellers = [...selectedSeller]
			if (checked) {
				newSelectedSellers.push(item)
			} else {
				newSelectedSellers = newSelectedSellers.filter(
					(el: any) => el?.id.toString() !== item?.id.toString()
				)
			}
			setSelectedSeller(newSelectedSellers)
		}

		sellers?.forEach((item: any) => {
			const isDuplicate = selectedSeller.some(
				(sellerItem: any) => sellerItem?.id.toString() === item?.id.toString()
			)

			if (!isDuplicate) {
				allItems.push({
					label: (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}
							onClick={e => e.stopPropagation()} // предотвращает закрытие
						>
							<Checkbox
								style={{ pointerEvents: 'auto' }} // позволяет работать с checkbox
								value={item?.company_name}
								checked={selectedSeller.some(
									(sellerItem: any) =>
										sellerItem?.id.toString() === item?.id.toString()
								)}
								onChange={e => handleCheckboxChange(item, e.target.checked)}
							>
								{item?.company_name}
							</Checkbox>
						</div>
					),
					key: `seller-${item.id.toString()}`,
				})
			}
		})

		selectedSeller?.forEach((item: any) => {
			selectedItems.push({
				label: (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '10px',
						}}
						onClick={e => e.stopPropagation()}
					>
						<Checkbox
							style={{ pointerEvents: 'auto' }}
							value={item?.company_name}
							checked={true}
							onChange={e => handleCheckboxChange(item, e.target.checked)}
						>
							{item?.company_name}
						</Checkbox>
					</div>
				),
				key: `seller-${item.id}`,
			})
		})

		const items: MenuProps['items'] = [
			{
				label: (
					<Input
						value={searchSellers}
						className={'analytic-dropdown-input'}
						onClick={e => e?.stopPropagation()}
						onChange={e => setSearchSellers(e?.target?.value)}
						prefix={<Icon component={searchIcon} />}
						placeholder={'Поиск...'}
					/>
				),
				key: 'sizes-search',
			},
			...selectedItems,
			...allItems,
		]

		return items
	}

	const addComment = useMutation(
		async ({
			product_id,
			comment,
		}: {
			product_id: string | number
			comment: string
		}) => await backend.advertisingMirror.addComment(product_id, comment),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('ADS_STATS_LIST')
			},
		}
	)

	const addDateComment = useMutation(
		async ({
			product_id,
			comment,
			date,
			weekId,
			avgWeekId,
		}: {
			product_id: string | number
			comment: string
			date?: string
			weekId?: number
			avgWeekId?: number
		}) =>
			await backend.advertisingMirror.addDateComment(
				product_id,
				comment,
				date,
				weekId,
				avgWeekId
			),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('ADS_STATS_LIST')
			},
		}
	)

	const planOrders = useMutation(
		async ({
			product_id,
			min,
			max,
		}: {
			product_id: string | number
			min: number
			max: number
		}) => await backend.advertisingMirror.planOrders(product_id, min, max),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('ADS_STATS_LIST')
			},
		}
	)

	const addOrChangeProductStatus = useMutation(
		async ({
			product_id,
			actionStatus,
			productStatus,
		}: {
			product_id: string | number
			actionStatus: string
			productStatus: string
		}) =>
			await backend.advertisingMirror.productStatus(
				product_id,
				actionStatus,
				productStatus
			),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('ADS_STATS_LIST')
			},
		}
	)

	const adStatus = useMutation(
		async ({
			product_id,
			adType,
			adRunStatus,
		}: {
			product_id: string | number
			adType: string
			adRunStatus: string
		}) =>
			await backend.advertisingMirror.adStatus(product_id, adType, adRunStatus),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('ADS_STATS_LIST')
			},
		}
	)

	const generateFilename = (name: string): string => {
		const currentDate = dayjs().format('YYYY-MM-DD')

		// const action = actionsData.find(
		// 	(action: { id: string | number; name: string }) =>
		// 		String(action.id) === String(actionId)
		// )

		// if (!action) {
		// 	console.error(`Action with id ${actionId} not found in actionsData`)
		// 	return `action_report_${currentDate}.xlsx`
		// }

		// return `${action.name}_${currentDate}.xlsx`

		return `${name}_${currentDate}.xlsx`
	}

	const downloadAdsStatsExcel = useMutation(
		async () =>
			backend.advertisingMirror.downloadAdsStatsExcel(
				dateStart,
				dateEnd,
				currentPage.toString(),
				currentPageSize,
				selectedSeller,
				selectedProducts,
				debouncedSearchArticle,
				selectedAvailableType
			),
		{
			onError: err => {
				console.error(`Failed to download data: ${err}`)
				message.error('Не удалось скачать Excel файл')
			},
			onSuccess: (data: Blob) => {
				const url = window.URL.createObjectURL(new Blob([data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', generateFilename('Продажи'))
				link.setAttribute('target', '_blank')
				document.body.appendChild(link)
				link.click()
				link.remove()
				message.success('Файл успешно загружен!')
			},
		}
	)

	return {
		statsData,
		currentPage,
		setCurrentPage,
		currentPageSize,
		dateStart,
		dateEnd,
		setDateStart,
		setDateEnd,
		setCurrentPageSize,
		selectedSeller,
		sellers,
		setSelectedSeller,
		searchSellers,
		setSearchSellers,
		type,
		setType,
		searchArticle,
		setSearchArticle,
		isWithElementsInTable,
		setIsWithElementsInTable,
		handleRangeChangeTable,
		getProductsFilterItems,
		setIsPlusMinusPhraseMode,
		getSellersFilterItems,
		setDataType,
		setSelectedAvailableType,
		selectedAvailableType,
		addComment: addComment.mutate,
		addDateComment: addDateComment.mutate,
		planOrders: planOrders.mutate,
		addOrChangeProductStatus: addOrChangeProductStatus.mutate,
		adStatus: adStatus.mutate,
		downloadAdsStatsExcel: downloadAdsStatsExcel.mutate,
		...options,
	}
}
