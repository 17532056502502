import React, { useEffect, useMemo, useState } from 'react'
import {
	Col,
	Dropdown,
	Input,
	message,
	Pagination,
	Row,
	Spin,
	Table,
	DatePicker,
} from 'antd'
import Icon, { LoadingOutlined } from '@ant-design/icons'
import selectIcon, {
	ReactComponent as SelectIconComponent,
} from '../../assets/images/select-icon.svg'

import { PickSeller } from './components/PickSeller'

import useUnitEconomicsData from './hooks/unit-economics/useUnitEconomicsData'
import { PickIsNew } from './components/PickIsNew'
import { PickPageSize } from './components/PickPageSize'
import CopyToClipboard from 'react-copy-to-clipboard'
import dayjs from 'dayjs'
import NoImage from 'assets/icon/no-image'
import TitleWithTooltip from 'features/actions/components/TitleWithTooltip'
import { useTableColumns } from './hooks/unit-economics/useTableColumns'
import { ReactComponent as rangePickerArrow } from '../../assets/images/rangepicker-arrow.svg'
import { PickIsAvailable } from './components/PickIsAvailable'

const { RangePicker } = DatePicker

const DetailsTable = () => {
	const {
		unitEconomicsData,
		selectedSeller,
		setSelectedSeller,
		sellers,
		setDataType,
		isLoading,
		onChangeDate,
		dateSortStart,
		dateSortEnd,
		getSellersFilterItems,
		selectedActionType,
		setSelectedActionType,
		searchText,
		setSearchText,
		selectedSeenType,
		setSelectedSeenType,
		selectedAvailableType,
		setSelectedAvailableType,
		currentPageSize,
		setCurrentPageSize,
		currentPage,
		setCurrentPage,
		getProductsFilterItems,
	} = useUnitEconomicsData()

	// const [data, setData] = useState(unitEconomicsData)
	const [data, setData] = useState<{ data: any[]; total: number } | null>(null)

	console.log(data)
	useEffect(() => {
		setDataType('details')
		if (unitEconomicsData) {
			setData(unitEconomicsData)
		}
	}, [unitEconomicsData])

	const columns = useMemo(
		() => [
			{
				title: 'Товар',
				dataIndex: 'photoUrl',
				key: 'photoUrl',
				width: '3%',
				align: 'center' as const,
				fixed: 'left',
				render: (photo: string, record: any) => {
					const { wbArticle, linkOnSite } = record || {}
					return (
						<div
							className='analytics-col-title__wrap'
							style={{ padding: '5px 6px 5px 6px' }}
						>
							<CopyToClipboard
								text={`${wbArticle || ''}`}
								onCopy={() => message.success('Текст скопирован')}
							>
								<div
									className='analytics-col-title'
									style={{ cursor: 'pointer' }}
								>
									{wbArticle || 'Нет данных'}
								</div>
							</CopyToClipboard>
							<a href={linkOnSite} target='_blank' rel='noopener noreferrer'>
								{photo ? (
									<img
										src={photo}
										style={{
											width: '90px',
											minHeight: 120,
											maxHeight: 120,
										}}
										alt='Товар'
										onError={e => {
											e.currentTarget.onerror = null
											e.currentTarget.src = ''
											e.currentTarget.style.display = 'none'
											const noImage = e.currentTarget
												.nextElementSibling as HTMLElement
											if (noImage) noImage.style.display = 'block'
										}}
									/>
								) : null}
								<NoImage
									style={{
										width: '90px',
										minHeight: 120,
										maxHeight: 120,
										display: photo ? 'none' : 'block',
									}}
								/>
							</a>
							{/* {category && <div className='analytics-col-title'>{category}</div>} */}
						</div>
					)
				},
			},
			{
				title: <TitleWithTooltip title='Период' />,
				dataIndex: 'startDate',
				key: 'startDate',
				width: '5%',
				align: 'center' as const,
				render(text: any, record: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{`${dayjs(record.startDate).format('DD.MM.YYYY')} - ${dayjs(
									record.endDate
								).format('DD.MM.YYYY')}`}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Номер детального отчета' />,
				dataIndex: 'reportIds',
				key: 'reportIds',
				width: '5%',
				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text?.map((item: any) => item)?.join(',')}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Артикул поставщика' />,
				dataIndex: 'articleChina',
				key: 'articleChina',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Себестоимость ед. товара, руб.' />,
				dataIndex: 'selfCost',
				key: 'selfCost',
				width: '5%',
				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Колличество продаж, шт.' />,
				dataIndex: 'salesCount',
				key: 'salesCount',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Средняя цена товара до СПП, руб.' />,
				dataIndex: 'avgPriceBeforeSpp',
				key: 'avgPriceBeforeSpp',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Логистика WB, руб.' />,
				dataIndex: 'logistics',
				key: 'logistics',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: (
					<TitleWithTooltip title='Логистика неопознанного товара, руб.' />
				),
				dataIndex: 'logisticsUnknown',
				key: 'logisticsUnknown',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Компенсации / удержания, руб.' />,
				dataIndex: 'wbCompensation',
				key: 'wbCompensation',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Хранение WB, руб.' />,
				dataIndex: 'wbStorageCost',
				key: 'wbStorageCost',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Расходы на рекламу с баланса, руб.' />,
				dataIndex: 'adsSpendBalance',
				key: 'adsSpendBalance',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Расходы на рекламу со счета, руб.' />,
				dataIndex: 'adsSpendBill',
				key: 'adsSpendBill',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Расходы на рекламу с бонусов, руб.' />,
				dataIndex: 'adsSpendBonus',
				key: 'adsSpendBonus',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Расходы на рекламу итого, руб.' />,
				dataIndex: 'adsSpendTotal',
				key: 'adsSpendTotal',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: (
					<TitleWithTooltip title='К перечислению за товар, за вычетом комиссий WB, руб.' />
				),
				dataIndex: 'toSellerRubWithoutWbCommission',
				key: 'toSellerRubWithoutWbCommission',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Штрафы, руб.' />,
				dataIndex: 'fines',
				key: 'fines',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: (
					<TitleWithTooltip title='Штрафы общие, без привязки к артикулу, руб.' />
				),
				dataIndex: 'finesUnknown',
				key: 'finesUnknown',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Платная приемка, руб.' />,
				dataIndex: 'receiptCost',
				key: 'receiptCost',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: (
					<TitleWithTooltip title='К перечислению за товар ( за вычетом логистики, хранения, рекламы (комиссия уже была вычтена ранее вычтена)), руб.' />
				),
				dataIndex: 'minusLogisticStorageAds',
				key: 'minusLogisticStorageAds',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Продажа в цене до СПП' />,
				dataIndex: 'salesInPriceBeforeSpp',
				key: 'salesInPriceBeforeSpp',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Возвраты в цене до СПП' />,
				dataIndex: 'returnsInPriceBeforeSpp',
				key: 'returnsInPriceBeforeSpp',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: (
					<TitleWithTooltip title='Комиссии ВБ  с продаж (коммисия, эквайринг, издержки первозки, НДС и пр), руб.' />
				),
				dataIndex: 'wbCommission',
				key: 'wbCommission',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: (
					<TitleWithTooltip title='Комиссии ВБ (коммисия, эквайринг, издержки первозки, НДС и пр), %' />
				),
				dataIndex: 'wbCommission',
				key: 'wbCommission',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Самовыкуп для фотоотзывов, шт.' />,
				dataIndex: 'selfBuyoutPhoto',
				key: 'selfBuyoutPhoto',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: (
					<TitleWithTooltip title='Самовыкуп для текстового отзыва, шт.' />
				),
				dataIndex: 'selfBuyoutText',
				key: 'selfBuyoutText',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Услуга самовыкупа, руб.' />,
				dataIndex: 'selfBuyoutCost',
				key: 'selfBuyoutCost',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Удержания WB с самовыкупов' />,
				dataIndex: 'wbBuyoutCommission',
				key: 'wbBuyoutCommission',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Расходы на самовыкупы, руб.' />,
				dataIndex: 'totalSelfBuyoutExpenses',
				key: 'totalSelfBuyoutExpenses',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Перечислено от WB c выкупа, руб.' />,
				dataIndex: 'sendToClientsForBuyout',
				key: 'sendToClientsForBuyout',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='ИТОГО удержания WB, руб.' />,
				dataIndex: 'totalWbSpends',
				key: 'totalWbSpends',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: (
					<TitleWithTooltip title='Доход на позицию с ДАШБОРДА за вычетом всех расходов WB и минуса с выкупов, руб.' />
				),
				dataIndex: 'incomeForPositionFromDashboard',
				key: 'incomeForPositionFromDashboard',
				width: '5%',
				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: (
					<TitleWithTooltip title='Доход на позицию на ед. товара за вычетом всех расходов WB, руб.' />
				),
				dataIndex: 'incomeForPosition',
				key: 'incomeForPosition',
				width: '5%',
				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Коэффициент наценки, %' />,
				dataIndex: 'markupCoef',
				key: 'markupCoef',
				width: '5%',

				align: 'center' as const,
				props: {
					style: {
						minWidth: 120,
					},
				},
				render(text: any) {
					return {
						props: {
							style: {
								minWidth: 120,
							},
						},
						children: (
							<div
								style={{
									padding: '0 8px',
								}}
							>
								{text ? text.toFixed(2) : text}
							</div>
						),
					}
				},
			},
		],
		[data]
	)

	const { visibleColumns, toggleColumn } = useTableColumns(columns)

	const tableColumns = visibleColumns.filter((col: any) => col.visible)

	return (
		<div style={{ backgroundColor: '#f8f8f8' }}>
			<Row gutter={6} align={'middle'} justify={'end'} style={{ gap: 5 }}>
				<Col style={{ flexGrow: '1' }}>
					<Input
						className='table-header-input'
						placeholder={'Поиск'}
						value={searchText}
						onChange={e => setSearchText(e.target.value)}
						type={'number'}
					/>
				</Col>
				<Col style={{ width: '120px' }}>
					<PickSeller
						getSellersFilterItems={getSellersFilterItems}
						selectedSeller={selectedSeller}
					/>
				</Col>
				<Col style={{ width: '160px' }}>
					<PickIsAvailable
						selectedAvailableType={selectedAvailableType}
						setSelectedAvailableType={setSelectedAvailableType}
					/>
				</Col>
				<Col style={{ width: '98px' }}>
					<Dropdown
						trigger={['click']}
						placement={'bottomRight'}
						menu={{ items: getProductsFilterItems() }}
						overlayClassName={'dropdown-border'}
					>
						<div className={'analytics-header-dropdown '}>
							<div>Предмет</div>
							<Icon
								component={SelectIconComponent}
								style={{ marginTop: '2px', fontSize: '10px' }}
							/>
						</div>
					</Dropdown>
				</Col>
				<Col>
					<RangePicker
						onChange={onChangeDate}
						placeholder={['Начало', 'Конец']}
						separator={<Icon component={rangePickerArrow} />}
						format={'DD.MM.YYYY'}
						value={[dateSortStart, dateSortEnd]}
					/>
				</Col>
				<Col style={{ width: '190px' }}>
					<PickPageSize
						currentPageSize={currentPageSize}
						setCurrentPage={setCurrentPage}
						setCurrentPageSize={setCurrentPageSize}
					/>
				</Col>
			</Row>

			<Row className={'container'} style={{ marginTop: '10px' }}>
				{isLoading ? (
					<div
						style={{
							margin: '0 auto',
							padding: '150px',
							textAlign: 'center',
						}}
					>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
					</div>
				) : data && data?.data.length === 0 ? (
					<div style={{ margin: '0 auto', padding: 150 }}>Нет данных</div>
				) : (
					<Col span={24}>
						<Table
							columns={tableColumns}
							locale={{
								emptyText: 'Нет данных',
								filterTitle: 'Фильтр',
								filterReset: 'Очистить',
								filterEmptyText: 'Нет фильтров',
							}}
							dataSource={data ? data.data : []}
							pagination={false}
							rowKey={(record: any) => record.id}
							scroll={{ x: 'max-content', y: '65vh' }}
						/>
						<Col span={24}>
							<Pagination
								onChange={page => setCurrentPage(page)}
								current={currentPage}
								defaultCurrent={1}
								pageSize={parseInt(currentPageSize)}
								total={data ? data?.total : 0}
								style={{
									textAlign: 'center',
									padding: '16px 0',
								}}
								showSizeChanger={false}
							/>
						</Col>
					</Col>
				)}
			</Row>
		</div>
	)
}

export default DetailsTable
